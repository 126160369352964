import { IOption, IPredefinedTerminologyList, IPredefinedTerminologyScaleList } from '@/lib';
import { ICodeValueEntity, Scale } from '@/models/clinical-blocks.model';

export const predefinedTerminologyListFactory = (data: ICodeValueEntity[] = []): IPredefinedTerminologyList => ({
  items: data,
  toOptions(): IOption[] {
    return this.items.map((terminology: ICodeValueEntity) => ({ value: terminology.code, label: terminology.label }));
  },
  find(code: string): ICodeValueEntity | undefined {
    return this.items.find((terminology: ICodeValueEntity) => terminology.code === code);
  }
});

export const predefinedTerminologyScaleListFactory = (data: Scale[]): IPredefinedTerminologyScaleList => ({
  items: data,
  toOptions(): IOption[] {
    return this.items.map((terminology: Scale) => ({
      value: terminology.symbol.code,
      label: terminology.symbol.label
    }));
  },
  find(code: string): Scale | undefined {
    return this.items.find((terminology: Scale) => terminology.symbol.code === code);
  }
});

export const terminologiesAreEqual = (a: ICodeValueEntity, b: ICodeValueEntity): boolean =>
  a.code === b.code && a.label === b.label;
