export const isNHSNumberValid = (input: string) => {
  // this function follows the NHS validation documentation here:
  // https://datadictionary.nhs.uk/attributes/nhs_number.html
  if (!input) {
    return false;
  }

  const value = input.replace(/ /g, '');

  if (value.split('').some((char: string) => isNaN(Number(char)))) {
    return false;
  }

  if (value.length !== 10) {
    return false;
  }

  const remainder: number =
    value
      .substring(0, 9)
      .split('')
      .reduce((acc: number, current: string, index: number) => {
        // Step 1: Multiply each of the first nine digits by a weighting factor
        const temp = +current * (11 - (index + 1));
        // Step 2: Add the results of each multiplication together.
        return acc + temp;
        // Step 3: Divide the total by 11 and establish the remainder.
      }, 0) % 11;

  // Step 4: Subtract the remainder from 11 to give the check digit.
  let validCheckDigit: number = 11 - remainder;

  // The tenth digit is a check digit used to confirm its validity.
  const inputCheckDigit = Number(value.substring(9, 10));

  // Step 5: Check the remainder matches the check digit.
  // If the result is 11 then a check digit of 0 is used.
  if (validCheckDigit === 11) {
    validCheckDigit = 0;
  }

  // If the result is 10 then the NHS NUMBER is invalid and not used.
  if (validCheckDigit === 10) {
    return false;
  }

  // Check the remainder matches the check digit. If it does not, the NHS NUMBER is invalid.
  return validCheckDigit === inputCheckDigit;
};

export const formattedNHSNumber = (input: string) => {
  const result = input.replace(/ /g, '');
  return `${result.substring(0, 3)} ${result.substring(3, 6)} ${result.substring(6, result.length)}`.trim();
};
