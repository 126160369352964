export const IBD = 'IBD';
export const COPD = 'COPD';

export const ONBOARDING_QUESTIONS = 'onboarding questions';

export enum HomeMonitoringPathways {
  COPD = 'copd-virtual-ward',
  CrohnsStable = 'ibd-crohns-disease-stable',
  CrohnsFlare = 'ibd-crohns-disease-flare',
  UlcerativeStable = 'ibd-ulcerative-colitis-stable',
  UlcerativeFlare = 'ibd-ulcerative-colitis-flare',
  CopdAdmissionAvoidance = 'copd-admission-avoidance',
  CopdEarlySupportedDischarge = 'copd-early-supported-discharge',
}
