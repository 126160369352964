<template>
  <svg data-dusk="loading-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g class="fill-current">
      <circle class="fill-current" cx="3" cy="12" r="2" />
      <circle class="fill-current" cx="12" cy="12" r="2" />
      <circle class="fill-current" cx="21" cy="12" r="2" />
    </g>
  </svg>
</template>

<style scoped>
svg g circle {
  opacity: 1;
  animation: loading-dots 0.8s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

svg g circle:nth-child(2) {
  animation-delay: 0.2s;
}

svg g circle:nth-child(3) {
  animation-delay: 0.3s;
}

@keyframes loading-dots {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
</style>
