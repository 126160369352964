export const isValidUKPostcode = (input: string) => {
  // UHB user can search for postcode with 3 or 4 characters, so the regex can do the partial search.
  const regex = new RegExp(
    '^[a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y0-9][a-hjkstuwA-HJKSTUW0-9]?[abehmnprvwxyABEHMNPRVWXY0-9]?\\ ?[0-9][abd-hjln-uw-zABD-HJLN-UW-Z]?[abd-hjln-uw-zABD-HJLN-UW-Z]?$'
  );

  if (!input) {
    return false;
  }

  return regex.test(input);
};

export const getPostcodeFromAddress = (address: { formatted_address: string }): string | null => {
  if (address && address.formatted_address) {
    const result = address.formatted_address.match(
      '([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\\s?[0-9][A-Za-z]{2})'
    );
    if (result && result.length) {
      return result[0];
    }
  }
  return null;
};
