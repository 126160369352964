import { AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  MyopiaAxialLengthPrognosisRequestBody,
  MyopiaPrognosis,
  MyopiaProgress,
  MyopiaSphericalEquivalentPrognosisRequestBody
} from '@/custom/menicon/models';
import apiClient from '@/services/api/client/apiClient';

export class MeniconMyopiaService {
  async getSphericalEquivalentPrognosis(
    body: MyopiaSphericalEquivalentPrognosisRequestBody,
    config: AxiosRequestConfig = {}
  ): Promise<AxiosResponse<MyopiaPrognosis>> {
    const params: AxiosRequestConfig = {
      ...config,
      params: {
        ...(config.params ? config.params : {}),
        ...body
      }
    };
    return await apiClient.get('menicon/v1/myopia/spheres', params);
  }

  async getAxialLengthPrognosis(
    body: MyopiaAxialLengthPrognosisRequestBody,
    config: AxiosRequestConfig = {}
  ): Promise<AxiosResponse<MyopiaPrognosis>> {
    const params: AxiosRequestConfig = {
      ...config,
      params: {
        ...(config.params ? config.params : {}),
        ...body
      }
    };
    return await apiClient.get('menicon/v1/myopia/axial', params);
  }

  async getMyopiaProgression(journeyId: string, config: AxiosRequestConfig = {}): Promise<Array<MyopiaProgress>> {
    return (await apiClient.get(`/menicon/v1/journeys/${journeyId}/myopia-progressions`, config)).data;
  }
}
