export const colors = [
  'transparent',
  'white',
  'black',
  'primary-50',
  'primary-100',
  'primary-200',
  'primary-300',
  'primary-400',
  'primary-500',
  'primary-600',
  'primary-700',
  'primary-800',
  'primary-900',
  'gray-50',
  'gray-100',
  'gray-200',
  'gray-300',
  'gray-400',
  'gray-500',
  'gray-600',
  'gray-700',
  'gray-800',
  'gray-900',
  'secondary-50',
  'secondary-100',
  'secondary-200',
  'secondary-300',
  'secondary-400',
  'secondary-500',
  'secondary-600',
  'secondary-700',
  'secondary-800',
  'secondary-900',
  'success-50',
  'success-100',
  'success-200',
  'success-300',
  'success-400',
  'success-500',
  'success-600',
  'success-700',
  'success-800',
  'success-900',
  'alert-50',
  'alert-100',
  'alert-200',
  'alert-300',
  'alert-400',
  'alert-500',
  'alert-600',
  'alert-700',
  'alert-800',
  'alert-900',
  'danger-50',
  'danger-100',
  'danger-200',
  'danger-300',
  'danger-400',
  'danger-500',
  'danger-600',
  'danger-700',
  'danger-800',
  'danger-900'
] as const;

export enum SavingStatus {
  UNSET,
  SAVING,
  SAVED,
  UNSAVED,
  ERROR
}
