import { computed } from 'vue';
import { HomeMonitoringPathways } from '@/lib/components/Programs/IbdProgram/constants';
import { i18n } from '@/i18n/i18n';
import { RouteLocation } from 'vue-router';
const { t } = i18n.global;

export const usePathwayName = (pathway: string): string => {
  switch (pathway) {
  case HomeMonitoringPathways.COPD:
    return t('custom.uhb.copd.name');
  case HomeMonitoringPathways.CrohnsStable:
    return t('custom.uhb.ibd.ibd-crohns-disease-stable');
  case HomeMonitoringPathways.CrohnsFlare:
    return t('custom.uhb.ibd.ibd-crohns-disease-flare');
  case HomeMonitoringPathways.UlcerativeStable:
    return t('custom.uhb.ibd.ibd-ulcerative-colitis-stable');
  case HomeMonitoringPathways.UlcerativeFlare:
    return t('custom.uhb.ibd.ibd-ulcerative-colitis-flare');
  case HomeMonitoringPathways.CopdAdmissionAvoidance:
    return t('custom.uhb.copd.copd-admission-avoidance');
  case HomeMonitoringPathways.CopdEarlySupportedDischarge:
    return t('custom.uhb.copd.copd-early-supported-discharge');
  default:
    return '';
  }
};

export const usePathway = (route: RouteLocation) =>
  computed<HomeMonitoringPathways>(() => {
    if (
      route.params.pathway === HomeMonitoringPathways.CopdAdmissionAvoidance ||
      route.params.pathway === HomeMonitoringPathways.CopdEarlySupportedDischarge
    ) {
      return route.params.pathway;
    }
    return HomeMonitoringPathways.CopdEarlySupportedDischarge;
  });

