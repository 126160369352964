// @ts-ignore
import queue from 'async/priorityQueue';
// @ts-ignore
import cornerstone from 'cornerstone-core/dist/cornerstone.min.js';
import { ImageSeries, Study } from '@/models';

const CONCURRENCY = 3;

export interface LoaderTask {
  studyId: string;
  seriesId: string;
  imageUri: string;
  id: string;
}

class ImageLoaderService {
  processedImages: { [seriesId: string]: string[] } = {};
  q = queue((task: LoaderTask, callback: any) => {
    if (!this.processedImages[task.seriesId]) {
      this.processedImages[task.seriesId] = [];
    }
    cornerstone
      .loadAndCacheImage(task.imageUri)
      .then(() => {
        this.processedImages[task.seriesId] = [...this.processedImages[task.seriesId], task.id];
        callback();
      })
      .catch(() => {
        this.processedImages[task.seriesId] = [...this.processedImages[task.seriesId] ?? [], task.id];
        callback(task);
      });
  }, CONCURRENCY);

  minPriority = 100;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  push(imageId: LoaderTask | LoaderTask[], priority = 100, callback = () => {
  }) {
    if (priority < this.minPriority) {
      this.minPriority = priority;
    }
    this.q.push(imageId, priority, callback);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  pushSeries(studyId: string, route: string, series: ImageSeries, priority = 100, callback = () => {
  }) {
    const tasks = series.images
      .filter((image) => !this.isProcessed(image.id))
      .map((image) => ({
        studyId,
        seriesId: series.id,
        imageUri: `${route}/${image.id}`,
        id: image.id
      }));
    this.push(tasks, priority, callback);
  }

  unshiftSeries(studyId: string, route: string, series: ImageSeries, callback: () => void) {
    const tasks = series.images
      .filter((image) => !this.isProcessed(image.id))
      .map((image) => ({
        studyId,
        seriesId: series.id,
        imageUri: `${route}/${image.id}`,
        id: image.id
      }));
    this.unshift(tasks, callback);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  pushStudy(route: string, study: Study, priority = 100, callback = () => {
  }) {
    const tasks = study.series.reduce((acc, val) => {
      val.images.map((image) => {
        if (!this.isProcessed(image.id)) {
          const value = {
            studyId: study.id,
            seriesId: val.id,
            imageUri: `${route}/${image.id}`,
            id: image.id
          };
          acc.push(value);
        }
      });
      return acc;
    }, [] as Array<LoaderTask>);
    this.push(tasks, priority, callback);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  unshift(imageId: LoaderTask | LoaderTask[], callback = () => {
  }) {
    this.minPriority -= 1;
    this.q.push(imageId, this.minPriority, callback);
  }

  kill() {
    this.q.kill();
  }

  emptyProcessedImages() {
    this.processedImages = {};
  }

  clear() {
    cornerstone.imageCache.purgeCache();
    this.emptyProcessedImages();
    this.q.kill();
  }

  isProcessed(imageId: string) {
    return typeof Object.values(this.processedImages).find((images) => images.includes(imageId)) !== 'undefined';
  }
}

export default new ImageLoaderService();
