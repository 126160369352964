import { KUnit } from '@/custom/menicon/models/encounter.model';

export interface CalculatedLens {
  materialCode: string;
  materialName: string;
  lensId: string;
  lensCode: string;
  lensName: string;
  lensFitting: {
    [key: string]: string;
  };
}

export type LensSimulationMarker =
  | 'Unknown'
  | 'NoMarkers'
  | 'MarkersLeftAndRight'
  | 'MarkersLeftRightAndBottom'
  | 'DoubleMarkersAtBottom';

export enum MeniconNotificationLevel {
  ALERT = 'Alert',
  WARNING = 'Warning',
  INFO = 'Info'
}

export interface MeniconNotification {
  id: string;
  level: MeniconNotificationLevel;
  parameters?: string[];
}

export interface MeniconLens {
  id: string;
  name: string;
  lensSimulation: string;
  lensSimulationMarker: LensSimulationMarker;
  materials: Array<MeniconLensMaterial>;
  params: Array<MeniconLensParameter>;
  quantity: Array<MeniconLensQuantity>;
  notifications: MeniconNotification[];
}

export interface MeniconLensQuantity {
  numeric: number;
  unitOfMeasurement: string;
}

export interface QuantityList {
  id: string;
  numeric: number;
  unitOfMeasurement: string;
}

export interface MeniconLensMaterial {
  isDefault: boolean;
  name: string;
  value: string;
}

export interface MeniconLensParameter {
  defaultValue: string | null;
  maxValue: number | '';
  minValue: number | '';
  name: string;
  stepValue: number | '';
  readOnly: boolean;
  precision?: number;
  visibility: 'Visible' | 'InvisibleButSentWithOrder';
  options: Array<MeniconLensParameterOption>;
}

export interface MeniconLensParameterOption {
  id: string | number;
  label: string;
  value: string | number;
}

export interface MeniconLensPropertiesRequestBody {
  has_topography: number;
  organisational_unit_id: string;
  laterality: string;
  r_mls_id?: string;
  r_sphere?: number;
  r_cylinder?: number;
  r_addition?: number;
  r_axis?: number;
  r_flat_k?: number;
  r_flat_k_axis?: number;
  r_steep_k?: number;
  r_k_axis?: number;
  r_hvid?: number;
  l_mls_id?: string;
  l_sphere?: number;
  l_cylinder?: number;
  l_addition?: number;
  l_axis?: number;
  l_flat_k?: number;
  l_flat_k_axis?: number;
  l_steep_k?: number;
  l_k_axis?: number;
  l_hvid?: number;
  k_unit: KUnit;
}
