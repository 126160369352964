import apiClient from '@/services/api/client/apiClient';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Inadequacy } from '@/models';

interface InadequateReason {
  id: string;
  comment?: string;
}

export class PatientSeriesService {
  private endpoint: string;

  constructor(patientId: string) {
    this.endpoint = `v1/patients/${patientId}/series`;
  }

  async markAsInadequate(
    seriesId: string,
    reasons: Array<InadequateReason>,
    params: AxiosRequestConfig = {}
  ): Promise<AxiosResponse<Array<Inadequacy>>> {
    return await apiClient.put(
      `${this.endpoint}/${seriesId}`,
      {
        reasons
      },
      params
    );
  }
}
