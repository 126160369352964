import { TooltipPosition } from '@/lib';

export const getFallbackPosition = (position: TooltipPosition): TooltipPosition =>
  position === 'below' || position === 'bottom'
    ? 'above'
    : position === 'above' || position === 'top'
      ? 'below'
      : position === 'left' || position === 'before'
        ? 'after'
        : position === 'right' || position === 'after'
          ? 'before'
          : position;

export const isElementVisible = (rect: DOMRect) => rect.top >= 0 && rect.bottom <= window.innerHeight;

export const getTooltipStyle = (
  position: TooltipPosition,
  tooltipEl: HTMLElement,
  targetEl: HTMLElement,
  horizontalAlignment: 'center' | 'right' | 'left' = 'center'
): string => {
  if (tooltipEl && targetEl) {
    const targetRect = targetEl.getBoundingClientRect();
    const contentW = tooltipEl.clientWidth;
    const contentH = tooltipEl.clientHeight;
    const targetW = targetEl.clientWidth;
    const targetH = targetEl.clientHeight;
    const horizontalPosition =
      horizontalAlignment === 'center'
        ? targetW / 2 - contentW / 2
        : horizontalAlignment === 'left'
          ? targetW - contentW
          : 0;
    const horizontalPosition2 =
      horizontalAlignment === 'center'
        ? (contentW - targetW) / 2
        : horizontalAlignment === 'left'
          ? contentW - targetW
          : 0;
    const left = (targetW > contentW ? horizontalPosition : -horizontalPosition2) + targetRect.left;
    const top = (targetH > contentH ? targetH / 2 - contentH / 2 : -(contentH / 2 - targetH / 2)) + targetRect.top;
    switch (position) {
    case 'before':
    case 'left':
      return `left:${-contentW - 8 + targetRect.left}px;top:${top}px`;
    case 'after':
    case 'right':
      return `left:${targetW + targetRect.left}px;top:${top}px`;
    case 'above':
    case 'top':
      return `left:${left}px;top:${targetRect.top - contentH - 8}px`;
    case 'below':
    case 'bottom':
      return `left:${left}px;top:${targetRect.top + targetH}px`;
    }
  }
  return '';
};
