import { GenericService } from './generic.service';
import {
  TagGroupApiFormat
} from '@/models';

export class TagGroupService extends GenericService<TagGroupApiFormat> {
  constructor() {
    super('v1/tag-groups');
  }
}
