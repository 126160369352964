import { TriageStatus } from '@/models';
import { GenericService } from '@/services/api/generic.service';
import { AxiosRequestConfig } from 'axios';

export class TriageStatusService extends GenericService<TriageStatus> {

  constructor() {
    super('v1/triage-statuses');
  }

  async getTriageStatusByName(
    name: string,
    clinicId: string
  ): Promise<TriageStatus> {
    const requestConfig: AxiosRequestConfig = {
      params: {
        'filter[clinic_id]': clinicId
      }
    };

    const triageStatuses: TriageStatus[] = (await this.index(requestConfig)).data;
    const triageStatus: TriageStatus|undefined = triageStatuses.find((status: TriageStatus) => status.name === name);
    if (!triageStatus) {
      throw new Error(`Triage status of name ${name} not found`);
    }
    return triageStatus;
  }
}
