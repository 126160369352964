import { AxiosResponse } from 'axios';
import apiClient from '@/services/api/client/apiClient';

export class HealthCheckService {
  endpoint = 'can-respond';

  async getHealthStatus(): Promise<AxiosResponse<string>> {
    return await apiClient.get(this.endpoint);
  }
}
