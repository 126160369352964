import { User } from '@/models';
import { AxiosRequestConfig } from 'axios';
import apiClient from '../client/apiClient';
import { GenericService } from '../generic.service';

export class AdminUserService extends GenericService<User> {
  endpoint = 'v1/admin/users';

  async resetMFA(userId: string, params: AxiosRequestConfig = {}): Promise<{ message: string }> {
    return await apiClient.post(`${this.endpoint}/${userId}/reset-mfa`, params);
  }
}
