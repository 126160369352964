import { ImageSeries } from '@/models';
import { i18n } from '@/i18n/i18n';

export const getSeriesDescription = (series: ImageSeries): string => {
  let laterality = '';
  if (series.laterality) {
    const lat = series.laterality.toLowerCase();
    if (lat.startsWith('l')) {
      laterality = i18n.global.t('platform.eyes.left-eye') as string;
    } else if (lat.startsWith('r')) {
      laterality = i18n.global.t('platform.eyes.right-eye') as string;
    }
  }
  if (laterality.length && series.description && series.description.length) {
    return `${laterality}: ${series.description}`;
  } else if (!laterality.length && series.description && series.description.length) {
    return series.description;
  } else if (laterality.length && !series.description) {
    return laterality;
  }
  return i18n.global.t('platform.scans.unknown-series') as string;
};
