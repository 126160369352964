export interface EyeData {
  initImage: string;
  initImageTime: string;
  lastImage: string;
  lastImageTime: string;
}

export enum DifferenceMapsType {
  AXIAL = 'axial',
  TANGENTIAL = 'tangential'
}

export enum MapTypeDescription {
  AXIAL = 'Axial Map',
  TANGENTIAL = 'Tangential Map'
}
