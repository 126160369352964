import { i18n } from '@/i18n/i18n';

export const UHB_NHS_NUMBER_KEY = 'UHB_NHS_NUMBER_KEY';
export const ORGANISATION_NAME_MAX_LENGTH = 50;
export const ORGANISATION_DESCRIPTION_MAX_LENGTH = 30;
export const FEATURES = {
  ALERT_NOTIFICATIONS: 'alert-notifications',
  ORGANISATION_LOCATIONS: 'organisation-locations',
  ORGANISATION_NAME_LIMIT: 'organisation-name-limit',
  EXTERNAL_LOOKUP_PATIENT: 'external-lookup-patient',
  PATIENT_EMAIL_MANDATORY: 'patient-email-mandatory',
  PATIENT_PHONE_MANDATORY: 'patient-phone-mandatory',
  PATIENT_ADDRESS_OPTIONAL: 'patient-address-optional',
  PATIENT_MRN_LIMIT: 'patient-mrn-limit',
  ORGANISATION_PHONE_AND_EMAIL: 'organisation-phone-email',
  FAMILY_HISTORY_CLINICAL_BLOCK: 'family-history-clinical-block',
  SMOKING_SUMMARY_CLINICAL_BLOCK: 'smoking-summary-clinical-block',
  LANGUAGE_PREFERENCES: 'language-preferences',
  VIRTUAL_DOCTOR: 'virtual-doctor',
  MYOPIA_PROGNOSIS: 'myopia-prognosis',
  UNIT_PREFERENCES: 'unit-preferences',
  BLOOM_DAY: 'menicon-bloom-day',
  SIX_MONTH_OPTIONAL_ORDERING: '6-month-optional-ordering',
  DIRECT_ORDER: 'direct-order',
  COLOUR_TRANSFORMATIONS: 'colour-transformations',
  CLINICAL_BLOCKS: 'clinical-blocks',
  TAGS: 'tags',
  PATHWAYS: 'pathways',
  WORKLISTS: 'worklists',
  PATIENT_RECORD_PATHWAYS: 'patient-record-pathways',
  IBD_PATHWAYS: 'ibd-pathways',
  SSO_AUTH: 'sso-auth',
  COPD_ADMISSION_AVOIDANCE: 'copd-admission-avoidance'
};

export const sexOptions = () => [
  { value: 'Female', label: i18n.global.t('platform.patient.female') as string },
  { value: 'Male', label: i18n.global.t('platform.patient.male') as string }
];

export const genderOptions = () => [
  { value: 'female', label: i18n.global.t('platform.patient.female') as string },
  { value: 'male', label: i18n.global.t('platform.patient.male') as string },
  { value: 'unknown', label: i18n.global.t('platform.patient.not-known') as string },
  { value: 'other', label: i18n.global.t('platform.patient.not-specified') as string }
];

export const genderOptionsExternalLookup = () => [
  { value: 'female', label: i18n.global.t('platform.patient.female') as string },
  { value: 'male', label: i18n.global.t('platform.patient.male') as string }
];

export const participantGenderOptions = () => [
  emptyOption(),
  ...sexOptions(),
  {
    value: 'Prefer not to say',
    label: i18n.global.t('platform.common.prefer-not-to-say') as string
  }
];

export const codeableConceptCategories = {
  participantType: 'participant-type',
  encounterStatus: 'encounter-status-code',
  encounterClass: 'encounter-class'
};

export const emptyOption = () => ({ value: '', label: i18n.global.t('platform.common.select-option') });

export const scansViewerColourFilters = () => [
  { value: '', label: i18n.global.t('custom.uhb.scans.no-colour-filter') as string },
  { value: 'redFree', label: i18n.global.t('custom.uhb.scans.red-free') as string },
  { value: 'falseColour', label: i18n.global.t('custom.uhb.scans.false-colour') as string }
];

export enum RouteNames {
  EPISODES_LIST = 'episode-list'
}

export enum ReasonsGroupType {
  CANCEL = 'cancel',
  REJECT = 'reject',
  RESOLVE = 'resolve'
}
