export const isValidNumber = (value: number, min: number, max: number, step: number) =>
  (value - min) % step === 0 && value >= min && value <= max;

export const getValidNumber = (value: number, min: number, max: number, step: number) => {
  if (min > max || step < 0 || step > max - min) {
    throw new Error('The given properties are incorrect.');
  }
  if (value < min) {
    return min;
  } else if (value > max) {
    return max;
  } else if ((value - min) % step === 0) {
    return value;
  }
  return value - ((value - min) % step);
};

export const countDecimals = (value: number): number => {
  if (value % 1 !== 0) {
    return value.toString().split('.')[1].length;
  }
  return 0;
};

interface ClosestStepMultipleProps {
  step?: number;
  min?: number;
  max?: number;
}
export const getClosestStepMultiple = (input: number, props: ClosestStepMultipleProps): number => {
  props.min = props.min ? props.min : 0;
  props.max = !!props.max || props.max === 0 ? props.max : 10;

  if (input <= props.min) {
    return props.min;
  }
  if (input >= props.max) {
    return props.max;
  }

  if (!props.step || !Number(props.step)) {
    return input;
  }
  props.step = Math.abs(props.step);

  const coeff = Math.pow(10, countDecimals(props.step));
  const reste = (Math.abs(input * coeff) % (props.step * coeff)) / coeff;
  if (reste === 0) {
    return input;
  }
  const next = input > 0 ? input - reste : input + reste;
  const prev = input > 0 ? next + props.step : next - props.step;
  return Math.abs(input - prev) > Math.abs(input - next) ? next : prev;
};
