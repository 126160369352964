import { MeniconTopographyParams } from '@/custom/menicon/models';
import apiClient from '@/services/api/client/apiClient';
import qs from 'qs';
import { AxiosRequestConfig } from 'axios';

export class MeniconFluorSimulationService {
  endpoint = '/menicon/v1/fluor-simulation';

  async getImage(params: MeniconTopographyParams, config: AxiosRequestConfig = {}) {
    const data = (await apiClient.get(`${this.endpoint}/image`, {
      ...config,
      params: {
        ...params,
        ...config.params
      },
      timeout: 200000,
      responseType: 'blob',
      paramsSerializer: (params) => qs.stringify(params, { encode: false })
    })) as BlobPart;

    return URL.createObjectURL(new Blob([data]));
  }

  async getHeightMap(params: MeniconTopographyParams, config: AxiosRequestConfig = {}): Promise<(number | string)[][]> {
    return (
      await apiClient.get(`${this.endpoint}/height-map`, {
        ...config,
        params: {
          ...params,
          ...config.params
        },
        timeout: 200000,
        paramsSerializer: (params) => qs.stringify(params, { encode: false })
      })
    ).data;
  }
}
