import { defineStore } from 'pinia';
import { INotification, ISnackbar } from '@/lib';
import { v4 } from 'uuid';

interface State {
  notifications: INotification[];
  snackbar: ISnackbar | null;
  top: number;
}

export const useNotificationStore = defineStore('notification', {
  state: () => ({
    notifications: [],
    snackbar: null,
    top: 0
  } as State),
  actions: {
    addNotification(notification: INotification) {
      this.notifications = [
        ...this.notifications,
        {
          ...notification,
          id: v4()
        }
      ];
    },
    addErrorNotification(notification: INotification) {
      this.addNotification({
        ...notification,
        icon: 'warning',
        color: 'danger-600'
      });
    },
    addErrorSnackbar(snackbar: ISnackbar) {
      this.snackbar = {
        autoDismiss: true,
        dismissAfter: 6000,
        color: 'danger',
        icon: 'warning',
        ...snackbar
      };
    },
    addSuccessNotification(notification: INotification) {
      this.addNotification({
        ...notification,
        icon: 'check',
        color: 'primary-500'
      });
    }
  }
});

