<template>
  <div class="flex items-center justify-center rounded-full w-20 h-20 bg-white">
    <InlineSvg id="loading-svg" :src="require('@/assets/branding/logos/logo-big-picture-medical-regular.svg')" class="" />
  </div>
</template>

<style scoped>
@keyframes fading {
  0% {
    opacity: 0.2;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}

#loading-svg {
  animation: fading 2s linear infinite;
  animation-delay: 200ms;
}
</style>
