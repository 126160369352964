import { GenericService } from './generic.service';
import { AxiosRequestConfig } from 'axios';
import { CopdMeasurementPayload, CopdProgramMeasurement, CopdProgramReview, JsonApiResponse } from '@/models';
import apiClient from '@/services/api/client/apiClient';
export class CopdProgramMeasurementService extends GenericService<CopdMeasurementPayload> {

  constructor(programId: string) {
    super(`copd/programs/${programId}/measurements`);
  }

  async indexWithIncludes(
    params: AxiosRequestConfig = {}
  ): Promise<JsonApiResponse<CopdProgramMeasurement[], CopdProgramReview[]>> {
    return await apiClient.get(`${this.endpoint}`, params);
  }
}
