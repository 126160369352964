import { Organisation } from '@/models';
import { GenericService } from '@/services/api/generic.service';
import { AxiosRequestConfig } from 'axios';
import apiClient from '@/services/api/client/apiClient';

export class OrganisationService extends GenericService<Organisation> {
  constructor() {
    super('v1/organisations');
  }

  async activate(organisationId: string, params: AxiosRequestConfig = {}) {
    return await apiClient.patch(`${this.endpoint}/${organisationId}/activate`, params);
  }

  async deactivate(organisationId: string, params: AxiosRequestConfig = {}) {
    return await apiClient.patch(`${this.endpoint}/${organisationId}/deactivate`, params);
  }
}
