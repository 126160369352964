import { defineStore } from 'pinia';

interface State {
  loading: boolean;
  bodyScrollLock: boolean;
  headerBranding: string | null;
}

export const useUiStore = defineStore('ui', {
  state: (): State => ({
    loading: false,
    bodyScrollLock: false,
    headerBranding: null
  })
});
