import { AxiosRequestConfig } from 'axios';
import apiClient from '@/services/api/client/apiClient';

export class FileService {
  endpoint = 'v1/files';

  async fetchBase64Pdf(id: string, params: AxiosRequestConfig = {}) {
    return await apiClient.get(`${this.endpoint}/${id}`, {
      ...params,
      headers: {
        ...(params.headers || {}),
        Accept: 'application/pdf;base64'
      }
    });
  }
}
