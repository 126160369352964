import { Patient } from '@/models';
import { genderOptions, sexOptions, UHB_NHS_NUMBER_KEY } from '@/constants';
import { formattedNHSNumber } from '@/lib/helpers/nhs-number.helper';

export const verifyMrn = (mrn: string): boolean => /^\S+$/.test(mrn);
export const validateName = (name: string): boolean => /^([^0-9]*)$/.test(name);

export const getNhsNumberFromPatient = (patient: Patient | null): string => {
  if (patient && patient.external_patient_references) {
    const patientNhsEpr = patient.external_patient_references.find(
      (epr) => epr.type && epr.type.key === UHB_NHS_NUMBER_KEY
    );
    if (patientNhsEpr && patientNhsEpr.value) {
      return formattedNHSNumber(patientNhsEpr.value);
    }
  }
  return '';
};

export const getDobFromISOString = (date: string): Date => {
  const d = new Date(date);
  const year = d.getUTCFullYear();
  const month = d.getUTCMonth();
  const day = d.getUTCDate();
  return new Date(year, month, day);
};

export const isMajorPatient = (patient: Patient): boolean => patient.merged_patient === null;

export const getSexOrGender = (patient: Patient, isOrgUsingGender = false): string | undefined => {
  let patientSexOrGender: { value: string, label: string };
  if (patient.gender) {
    patientSexOrGender = genderOptions().find(({ value }) => value === patient.gender);
  }
  if (isOrgUsingGender) {
    return patientSexOrGender?.label || patient.gender;
  }

  const sex = sexOptions().find(({ value }) => value === patient.sex);
  return sex?.label || patient.sex || patientSexOrGender?.label || patient.gender;
};
