import { GenericService } from './generic.service';
import { SupportTicket } from '@/models';

export class SupportTicketsService extends GenericService<SupportTicket> {

  constructor() {
    super('v1/support-tickets');
  }

}
