<template>
  <div class="flex flex-col bg-white p-5 border rounded-lg space-y-4">
    <div class="flex flex-row justify-between items-center">
      <slot name="question" />
      <div class="flex flex-row items-center">
        <slot name="options" />
      </div>
    </div>
    <slot />
  </div>
</template>
