/**
 * The truncation points are defined by null.
 * @param {Array<number>} pagesList The pages list.
 * @param {number} pagesLimit The number of pages to display.
 * @param {number} index The current page number.
 * @returns {Array<number | null>} returns an array with null when it has to be truncated.
 */
export const getPages = (pagesList: Array<number>, pagesLimit: number, index = 1): Array<number | null> => {
  const pagesLength = pagesList.length;

  // All the following logic depends on showing at least 5 pages
  const limit = pagesLimit < 5 ? 5 : pagesLimit;

  // Index starts at 1
  if (index < 1) {
    throw new Error('The index value has to be positive.');
  }

  // If the limit is already less than or equal to the number of pages, return the complete page list
  if (pagesLength <= pagesLimit) {
    return pagesList;
  }

  /*
  If the current page is more than 2 pages away from number of pages to display (limit) then:
  1. Add all pages up to limit - 2
  2. Put a null to show ellipsis
  3. Add the last page.
  Limit = 5
  [1,2,3,4,5,6,7,8,9,10]
       ^  <--- Index = 3
  [1,2,3,null,10]
   */
  if (index <= limit - 3) {
    // Truncate before the last number
    return [...[...pagesList].slice(0, limit - 2), null, pagesLength];
  }

  /*
  If the current page is one of the last 2 pages then:
  1. Add the first page
  2. Put a null to show ellipsis
  3. Add the last pages up to limit - 2
  Limit = 5
  [1,2,3,4,5,6,7,8,9,10]
                   ^  <--- Index = 9
  [1,null,8,9,10]
   */
  if (index > pagesLength - 3) {
    // Truncate after the first number
    return [1, null, ...[...pagesList].slice(pagesLength - (limit - 2))];
  }

  /*
  If the current page is greater than the number of pages to display but is not one of the last 2 pages then:
  1. Add the first page
  2. Put a null to show ellipsis
  3. Add filler pages in between to reach pages to display limit (these filler pages include the curent page)
  4. Put a null to show ellipsis
  5. Add the last page
  Limit = 7 | Odd number of items to fill
  [1,2,3,4,5,6,7,8,9,10]
             ^  <--- Index = 6
  [1,null,5,6,7,null,10]

  Limit = 6 | Even number of items to fill
  [1,2,3,4,5,6,7,8,9,10]
             ^  <--- Index = 6
  [1,null,6,7,null,10]
   */
  let fromIndex;
  let toIndex;
  const numberOfItems = limit - 4; // First page, ellipsis x 2 and last page take up 4 items
  // If the number of pages to fill in is even...
  if (numberOfItems % 2 === 0) {
    // ...divide it by 2 and subtract it from the current page index to get the first page of the filler pages
    fromIndex = index - numberOfItems / 2;
    // ...divide it by 2 and add it to the current page index to get the last page of the filler pages
    toIndex = index + numberOfItems / 2;
    // If the number of pages to fill in is odd...
  } else {
    // ...make it even by adding 1, divide by 2 and subtract from the current page index to get the first page of the filler pages. This will include the middle filler element (the current page) plus everything to the left of it.
    fromIndex = index - (numberOfItems + 1) / 2;
    // ...make it even by adding 1, divide by 2, subtract 1 and add from the current page index to get the last page of the filler pages. This will EXCLUDE the middle filler element (the current page) and only include the right of it.
    toIndex = index + (numberOfItems + 1) / 2 - 1;
  }
  return [1, null, ...[...pagesList].slice(fromIndex, toIndex), null, pagesLength];
};
