import {
  ICodeValueEntity,
  BodyTemperatureObservation,
  BorgBreathlessnessScaleObservation,
  PulseObservation,
  PulseOximetryObservation,
  RespirationObservation,
  UhbModifiedAnthonisenCriteriaObservation
} from './clinical-blocks.model';
import { FilterTag } from './filter-tag.model';
import { Worklist } from '@/models/episodes.model';

export interface CopdProgramSetting {
  spo2_range_low: number | null;
  spo2_range_high: number | null;
  method_of_oxygen_delivery: ICodeValueEntity;
  flow_rate?: string | null;
  home_monitoring?: string;
}

export interface CopdProgramSettingResource {
  id: string;
  type: 'copdHomeMonitoringProgramSettings';
  spo2_range_low: number;
  spo2_range_high: number;
  method_of_oxygen_delivery: ICodeValueEntity;
  flow_rate?: string;
}

export interface CopdProgramRequest extends CopdProgram {
  assigned_organisational_unit_id?: string;
}
export interface CopdProgram {
  settings: CopdProgramSetting;
  latest_consult: CopdProgramConsult | null;
  hospital_team?: FilterTag['id'];
  community_team?: FilterTag['id'];
  home_monitoring?: FilterTag['id'];
  hospital_team_name?: string;
  created_at?: string;
  high_risk?: boolean;
  pathway?: string
  worklists?: Worklist[]
}

export interface CopdProgramRequestPayload {
  hospital_team?: FilterTag['id'];
  community_team?: FilterTag['id'];
  home_monitoring?: FilterTag['id'];
}

export interface CopdRelationship {
  type: string;
  id: string;
  created_at?: string;
}

export interface DischargedReview {
  outcome: CopdOutcome;
  reason: CopdReason;
  created_at: string;
  created_by: string;
}

export interface CopdAllProgramItem {
  assigned_organisational_unit_id?: string;
  created_at: string;
  created_by_user_id: string;
  discharged_program_review_id?: string;
  discharged_review?: DischargedReview;
  id: string;
  owner_organisational_unit_id: string;
  patient_id: string;
  tags: Array<any>;
  updated_at: string;
}
export interface CopdProgramResponseData {
  id: string;
  type: string;
  attributes: CopdProgram;
  relationships: {
    [key: string]: CopdRelationship | Array<CopdRelationship>;
  };
}
export interface CopdProgramIncludedReviews {
  id: string;
  type: string;
  attributes: CopdProgramReview;
  relationships: {
    [key: string]: CopdRelationship;
  };
}

export interface CopdProgramIncludedConsults {
  id: string;
  type: string;
  attributes: CopdProgramConsult;
  relationships: {
    [key: string]: CopdRelationship;
  };
}

export interface CopdDischarge {
  created_at: string;
  created_by: string;
  reason: string;
}
export interface CopdProgramResponse {
  data: CopdProgramResponseData;
  included?: Array<any>;
}

export interface CopdMeasurementPayload {
  pulse: PulseObservation;
  pulse_oximetry: PulseOximetryObservation;
  body_temperature?: BodyTemperatureObservation;
  respiration?: RespirationObservation;
  borg_breathlessness_scale: BorgBreathlessnessScaleObservation;
  uhb_modified_anthonisen_criteria: UhbModifiedAnthonisenCriteriaObservation;
}
export interface CopdProgramMeasurement {
  id: string;
  type: 'copdHomeMonitoringProgramMeasurements';
  attributes: CopdMeasurementPayload & {
    created_at: string;
    created_by: string;
    high_risk: boolean;
  };
  relationships: {
    [key: string]: CopdRelationship;
  };
}

export enum CopdOutcome {
  change = 'change',
  continue = 'continue',
  discharge = 'discharge'
}

export enum CopdReason {
  setNextHomeVisitOrTelephoneConsult,
  generalClinicalImprovement,
  notImprovingAsExpected,
  generalisedClinicalDeterioration,
  unexpectedResultInRecording,
  patientHasIncreasedTheirO2,
  patientRequiresFurtherTraining,
  changeInMedication,
  equipmentFault,
  reasonNotListed
}

export enum CopdDischargeReason {
  patientNotDischargedFromHospital,
  patientFailedTechnicalTraining,
  patientImprovedAndDischarged,
  readmittedForCopd,
  admittedToHospitalForNonCopdIssue,
  patientDeceased,
  patientNonCompliantWithVirtualEntries,
  patientNotHappyDoingVirtualEntries,
  patientHavingTechnicalDifficulty,
  reasonNotListed
}

export enum CopdConsultType {
  homeVisit = 'homeVisit',
  telephoneConsult = 'telephoneConsult'
}

export interface JsonApiResponse<TData, TIncluded> {
  data: TData;
  included?: TIncluded;
  links?: {
    first: string;
    last: string;
    prev?: string;
    next?: string;
  };
  meta?: {
    current_page: number;
    from?: number;
    last_page?: number;
    path?: string;
    per_page: number;
    to?: number;
    total: number;
  };
}

export interface CopdProgramReviewConsult {
  consult_type: CopdConsultType;
  consult_at: string;
}

export interface CopdProgramConsult {
  type: CopdConsultType;
  scheduled_at: string;
}

export interface CopdProgramReview {
  id: string;
  type: 'copdHomeMonitoringProgramReviews';
  attributes: {
    outcome: CopdOutcome;
    reason: CopdReason;
    next_consult_type: CopdConsultType;
    next_consult_at: string;
    created_at: string;
    created_by: string;
  };
}

export interface CopdMeasurementReviewParams {
  outcome?: CopdOutcome;
  reason?: CopdReason | CopdDischargeReason;
  next_consult_type?: CopdConsultType;
  next_consult_at?: string;
  measurements?: string[];
}
export interface CopdMeasurementDischargeParams {
  outcome: CopdOutcome.discharge;
  reason: CopdDischargeReason;
  measurements: string[];
}

export interface CopdHomeMonitoringProgramRow {
  id: string;
  type: 'copdHomeMonitoringProgram';
  attributes: {
    created_at: string;
    high_risk?: boolean;
  };
  relationships: {
    [key: string]: CopdRelationship;
  };
}
