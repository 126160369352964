import { GenericService } from './generic.service';
import { AxiosRequestConfig } from 'axios';
import {
  FilterTag,
  FilterTagApiFormat,
  TagGroupName
} from '@/models';
import apiClient from './client/apiClient';

export class FilterTagService extends GenericService<FilterTagApiFormat> {

  constructor() {
    super('v1/tags');
  }

  async getAllTags(params: AxiosRequestConfig = {}): Promise<Array<FilterTag>> {
    const apiFormatTags: Array<FilterTagApiFormat> = (await apiClient.get(`${this.endpoint}`, params)).data;
    return apiFormatTags.map(this.mapApiFormatToFrontEndFormat);
  }

  mapApiFormatToFrontEndFormat(apiFormatTag: FilterTagApiFormat): FilterTag {
    return {
      id: apiFormatTag.id,
      parentId: apiFormatTag.parent_id,
      groupId: apiFormatTag.group_id,
      name: apiFormatTag.name,
      createdAt: apiFormatTag.created_at,
      updatedAt: apiFormatTag.updated_at
    };
  }

  async getTagsForGroupName(
    groupName: TagGroupName,
    params: AxiosRequestConfig = {}
  ): Promise<Array<FilterTag>> {
    const apiFormatTags: Array<FilterTagApiFormat> = (await apiClient.get(`${this.endpoint}/group/${groupName}`, params)).data;
    return apiFormatTags.map(this.mapApiFormatToFrontEndFormat);
  }
}
