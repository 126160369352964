import { GooglePlaceAutocomplete, GooglePlaceDetails, AutocompletePrediction } from '@/models';
import PlacesService = google.maps.places.PlacesService;
import AutocompleteService = google.maps.places.AutocompleteService;
import PlaceResult = google.maps.places.PlaceResult;

export class GoogleApiService {
  autocompleteApi: AutocompleteService = new google.maps.places.AutocompleteService();
  placeDetailsApi!: PlacesService;

  async placeAutocomplete(input: string): Promise<GooglePlaceAutocomplete> {
    return await new Promise((resolve) => {
      this.autocompleteApi.getPlacePredictions({ input }, (predictions: AutocompletePrediction[], status) => {
        resolve({ predictions, status });
      });
    });
  }

  async placeDetails(placeId: string): Promise<GooglePlaceDetails> {
    if (!this.placeDetailsApi) {
      const mapDiv: HTMLDivElement = document.getElementById('mapAttr') as HTMLDivElement;
      this.placeDetailsApi = new google.maps.places.PlacesService(mapDiv);
    }

    return await new Promise((resolve) => {
      this.placeDetailsApi.getDetails({ placeId }, (result: PlaceResult, status: string) => {
        resolve({ result, status });
      });
    });
  }
}
