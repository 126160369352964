export enum TemperatureUnits {
  Celsius = 'Cel',
  Fahrenheit = '[degF]'
}

export interface ICodeValueEntity {
  code: string;
  label: string;
}

export interface Scale {
  symbol: ICodeValueEntity;
  value: number;
}

export interface Ordinal {
  symbol: ICodeValueEntity;
  value: number;
}

export interface Quantity {
  magnitude: number;
  units: TemperatureUnits;
}

export interface PulseObservation {
  rate: number;
}

export interface PulseOximetryObservation {
  flow_rate?: number;
  method_of_oxygen_delivery: ICodeValueEntity;
  spo2: number;
}

export interface BodyTemperatureObservation {
  temperature: Quantity;
}

export interface RespirationObservation {
  rate: number;
}

export interface BorgBreathlessnessScaleObservation {
  amount_of_breathlessness: Scale;
}

export interface UhbModifiedAnthonisenCriteriaObservation {
  breathlessness: Ordinal;
  congested_runny_nose: Ordinal;
  cough: Ordinal;
  sore_throat: Ordinal;
  sputum_colour: Ordinal;
  sputum_production: Ordinal;
  wheeze: Ordinal;
  total_score?: number;
}
