export const getValueFromPosition = (
  posX: number,
  min: number,
  max: number,
  boundingClientX: number,
  boundingClientWidth: number
) => {
  const newRange = max - min;
  const oldValue = posX - boundingClientX;
  const value = Math.round((oldValue * newRange) / boundingClientWidth + min);
  return value < min ? min : value > max ? max : value;
};

export const getValueFromPositionVertical = (
  posY: number,
  min: number,
  max: number,
  boundingClientY: number,
  boundingClientHeight: number
) => {
  const newRange = max - min;
  const oldValue = posY - boundingClientY;
  const value = Math.round((oldValue * newRange) / boundingClientHeight + min);
  return value < min ? min : value > max ? max : value;
};

export const getPercent = (value: number, min: number, max: number): number => ((value - min) * 100) / (max - min);

export const checkProps = (min: number, max: number, value: number, step: number): boolean =>
  min <= max && value <= max && value >= min && step > 0 && step <= max - min;

export const checkRangeProps = (min: number, max: number, value: Array<number>, step: number): boolean =>
  min <= max &&
  value[1] <= max &&
  value[0] >= min &&
  step > 0 &&
  step <= max - min &&
  value.length === 2 &&
  value[0] <= value[1];
