import { IOption } from '@/lib';
import { i18n } from '@/i18n/i18n';

export const defaultRoutes = (locale = i18n.global.locale.value): IOption[] => [
  {
    label: i18n.global.t('platform.medication.routes.oral', [], { locale }) as string,
    value: 'Oral'
  },

  { value: 'Inhaled', label: i18n.global.t('platform.medication.routes.inhaled', [], { locale }) as string },

  {
    label: i18n.global.t('platform.medication.routes.topical', [], { locale }) as string,
    value: 'Topical'
  },
  {
    label: i18n.global.t('platform.medication.routes.both-eyes', [], { locale }) as string,
    value: 'Both Eyes'
  },
  {
    label: i18n.global.t('platform.medication.routes.right-eye', [], { locale }) as string,
    value: 'Right Eye'
  },
  {
    label: i18n.global.t('platform.medication.routes.left-eye', [], { locale }) as string,
    value: 'Left Eye'
  },
  {
    label: i18n.global.t('platform.medication.routes.both-ears', [], { locale }) as string,
    value: 'Both Ears'
  },
  {
    label: i18n.global.t('platform.medication.routes.right-ear', [], { locale }) as string,
    value: 'Right Ear'
  },
  {
    label: i18n.global.t('platform.medication.routes.left-ear', [], { locale }) as string,
    value: 'Left Ear'
  },
  {
    label: i18n.global.t('platform.medication.routes.intramuscular', [], { locale }) as string,
    value: 'Intramuscular'
  },
  {
    label: i18n.global.t('platform.medication.routes.subcutaneous', [], { locale }) as string,
    value: 'Subcutaneous'
  },
  {
    label: i18n.global.t('platform.medication.routes.intravenous', [], { locale }) as string,
    value: 'Intravenous'
  },
  {
    label: i18n.global.t('platform.medication.routes.sublingual', [], { locale }) as string,
    value: 'Sublingual'
  },
  {
    label: i18n.global.t('platform.medication.routes.rectal', [], { locale }) as string,
    value: 'Rectal'
  },
  {
    label: i18n.global.t('platform.medication.routes.vaginal', [], { locale }) as string,
    value: 'Vaginal'
  }
];
export const doseUnitOptions = (locale = i18n.global.locale.value): IOption[] => [
  {
    value: 'mg',
    label: i18n.global.t('platform.medication.units.mg', [], { locale }) as string
  },
  {
    value: 'mL',
    label: i18n.global.t('platform.medication.units.ml', [], { locale }) as string
  },
  {
    value: 'drops',
    label: i18n.global.t('platform.medication.units.drops', [], { locale }) as string
  },
  {
    value: 'tablets',
    label: i18n.global.t('platform.medication.units.tablets', [], { locale }) as string
  },
  {
    value: 'units',
    label: i18n.global.t('platform.medication.units.units', [], { locale }) as string
  },
  {
    value: 'apply',
    label: i18n.global.t('platform.medication.units.apply', [], { locale }) as string
  },
  {
    value: 'puffs',
    label: i18n.global.t('platform.medication.units.puffs', [], { locale }) as string
  },
  {
    value: 'micrograms',
    label: i18n.global.t('platform.medication.units.micrograms', [], { locale }) as string
  }
];
export const frequencyOptions = (locale = i18n.global.locale.value): IOption[] => [
  {
    label: i18n.global.t('platform.medication.frequencies.daily', [], { locale }) as string,
    value: 'Daily'
  },
  {
    value: 'Twice Daily',
    label: i18n.global.t('platform.medication.frequencies.2-daily', [], { locale }) as string
  },
  {
    label: i18n.global.t('platform.medication.frequencies.3-daily', [], { locale }) as string,
    value: 'Three times a day'
  },
  {
    label: i18n.global.t('platform.medication.frequencies.4-daily', [], { locale }) as string,
    value: 'Four times a day'
  },
  {
    label: i18n.global.t('platform.medication.frequencies.night', [], { locale }) as string,
    value: 'At night'
  },
  {
    label: i18n.global.t('platform.medication.frequencies.morning', [], { locale }) as string,
    value: 'In the morning'
  },
  {
    label: i18n.global.t('platform.medication.frequencies.9', [], { locale }) as string,
    value: '0900'
  },
  {
    label: i18n.global.t('platform.medication.frequencies.12', [], { locale }) as string,
    value: '1200'
  },
  {
    label: i18n.global.t('platform.medication.frequencies.15', [], { locale }) as string,
    value: '1500'
  },
  {
    label: i18n.global.t('platform.medication.frequencies.18', [], { locale }) as string,
    value: '1800'
  },
  {
    label: i18n.global.t('platform.medication.frequencies.21', [], { locale }) as string,
    value: '2100'
  },
  {
    label: i18n.global.t('platform.medication.frequencies.hourly', [], { locale }) as string,
    value: 'Hourly'
  },
  {
    label: i18n.global.t('platform.medication.frequencies.2-hourly', [], { locale }) as string,
    value: 'Every 2 Hours'
  },
  {
    label: i18n.global.t('platform.medication.frequencies.3-4-hourly', [], { locale }) as string,
    value: 'Every 3-4 Hours'
  },
  {
    label: i18n.global.t('platform.medication.frequencies.6-hourly', [], { locale }) as string,
    value: 'Every 6 Hours'
  },
  {
    label: i18n.global.t('platform.medication.frequencies.weekly', [], { locale }) as string,
    value: 'Weekly'
  },
  {
    label: i18n.global.t('platform.medication.frequencies.fortnightly', [], { locale }) as string,
    value: 'Fortnightly'
  },
  {
    label: i18n.global.t('platform.medication.frequencies.monthly', [], { locale }) as string,
    value: 'Monthly'
  },
  {
    label: i18n.global.t('platform.medication.frequencies.3-monthly', [], { locale }) as string,
    value: '3 Monthly'
  },
  {
    label: i18n.global.t('platform.medication.frequencies.6-monthly', [], { locale }) as string,
    value: '6 Monthly'
  },
  {
    label: i18n.global.t('platform.medication.frequencies.yearly', [], { locale }) as string,
    value: 'Yearly'
  }
];
