import axios from 'axios';

export const getBrand = async () => {
  try {
    const response = await axios.get('/');
    return response.headers?.['bpm-brand'] || '';
  } catch (e) {
    return '';
  }
};
