import { AxiosRequestConfig, AxiosResponse } from 'axios';
import apiClient from '@/services/api/client/apiClient';
import { OrderConfiguration } from '@/custom/menicon/models';

export class MeniconOrganisationService {
  private endpoint: string;

  constructor(organisationId: string) {
    this.endpoint = `menicon/v1/organisational-units/${organisationId}`;
  }

  async getOrderConfiguration(params: AxiosRequestConfig = {}): Promise<AxiosResponse<OrderConfiguration>> {
    return await apiClient.get(`${this.endpoint}/order-configuration`, params);
  }

  async updateOrCreateOrderConfiguration(data: OrderConfiguration, params: AxiosRequestConfig = {}) {
    return await apiClient.post(`${this.endpoint}/order-configuration`, data, params);
  }
}
