const comfortableAnswers = ['Very Uncomfortable', 'Uncomfortable', 'Comfortable', 'Very Comfortable'];

const clearAnswers = ['Not Clear At All', 'Not So Clear', 'Clear', 'Very Clear'];

const badOkGoodAnswers = ['Bad', 'Ok', 'Good'];

const yesNoAnswers = ['No', 'Yes'];

export const results: { [question: string]: string[] } = {
  'How long did you wear your lenses today?': ['>14hrs', '4 to 13 hrs Normal', '<4hrs'],
  'How was your vision while wearing your lenses today?': clearAnswers,
  'How was your experience removing your lenses today?': badOkGoodAnswers,
  'How was your experience handling/inserting your lenses today?': badOkGoodAnswers,
  'How was your experience handling/inserting your lenses?': badOkGoodAnswers,
  'How was your experience removing your lenses in the morning?': badOkGoodAnswers,
  'How was your experience handling/inserting your lenses last night?': badOkGoodAnswers,
  'How comfortable were your lenses overall during the night? - Did you feel your lenses while you were sleeping?':
    comfortableAnswers,
  'How comfortable were your lenses after you put them in?': comfortableAnswers,
  'How comfortable are your lenses after inserting them?': comfortableAnswers,
  'How comfortable were your lenses after inserting them?': comfortableAnswers,
  'How comfortable were your lenses before removing them?': comfortableAnswers,
  'How comfortable are your lenses before removing them?': comfortableAnswers,
  'How did your eyes feel after you removed your lenses?': comfortableAnswers,
  'How satisfied are you with your lenses?': ['Not Satisfied', 'Neutral', 'Satisfied', 'Very Satisfied'],
  'Are you happy with your Bloom program?': ['No', 'Neutral', 'Yes'],
  'How long did you wear your lenses last night?': ['>13 hrs', '6-12 hrs', '<6hrs'],
  'How long do you usually wear your lenses at night?': ['>13 hrs', '6-12 hrs', '<6hrs'],
  'How is your vision before inserting your lenses?': clearAnswers,
  'How was your vision after removing your lenses?': clearAnswers,
  'How has your vision been?': clearAnswers,
  'Do you have enough time to clean your lenses in the morning?': yesNoAnswers,
  'Did you clean your lenses after removing them?': yesNoAnswers,
  'Have you cleaned your lenses with Menicon Bloom Progent yet?': yesNoAnswers,
  'Did you clean and dry your lens case last night?': yesNoAnswers,
  'Do you clean and dry your lens case every day?': yesNoAnswers,
  'Do you replace your lens case with each new bottle of solution?': yesNoAnswers,
  'Do you have enough time to clean and dry your lens case in the evening?': yesNoAnswers,
  'How was your experience removing your lenses?': badOkGoodAnswers
};
