import { GenericService } from './generic.service';
import apiClient from '@/services/api/client/apiClient';
import { AxiosResponse } from 'axios';
import { ReasonsGroupType } from '@/constants';

export class ReasonsService extends GenericService<string> {

  constructor(reasonGroup: ReasonsGroupType) {
    super(`v1/consult-${reasonGroup}-reasons`);
  }

  async getOptions(): Promise<AxiosResponse<string[]>> {
    return await apiClient.get(this.endpoint);
  }
}
