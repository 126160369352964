import { AxiosRequestConfig } from 'axios';
import apiClient from '@/services/api/client/apiClient';

export class DocumentService {
  endpoint = 'v1/documents';

  async requestOTPForDocument(id: string) {
    return await apiClient.post(`${this.endpoint}/${id}/otp-request`);
  }

  async fetchBase64Pdf(id: string | number, params: AxiosRequestConfig = {}): Promise<string> {
    return await apiClient.get(`${this.endpoint}/${id}`, {
      ...params,
      headers: {
        ...(params.headers || {}),
        Accept: 'application/pdf;base64'
      }
    });
  }
}
