import { GenericService } from './generic.service';
import apiClient from '@/services/api/client/apiClient';
export class EncounterService extends GenericService {
    constructor() {
        super('v1/encounters');
    }
    async resend(encounterId, params = {}) {
        return await apiClient.post(`${this.endpoint}/${encounterId}/resend-patient-letter`, params);
    }
    async getConsultTypes(params = {}) {
        return await apiClient.get('v1/consult-types', params);
    }
}
