<template>
  <div class="min-h-screen bg-primary-100">
    <div class="floating-brand-shapes">
      <div class="floating-brand-shape">
        <img src="./../../../js/assets/images/brand/brand-shape-1.png" alt="">
      </div>
      <div class="floating-brand-shape">
        <img src="./../../../js/assets/images/brand/brand-shape-2.png" alt="">
      </div>
      <div class="floating-brand-shape">
        <img src="./../../../js/assets/images/brand/brand-shape-3.png" alt="">
      </div>
    </div>

    <div class="min-h-screen h-2 px-8 flex items-center justify-center relative z-10 lg:px-12">
      <slot />
    </div>
  </div>
</template>
