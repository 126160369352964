import { GenericService } from './generic.service';
import { Annotation } from '@/models';
import apiClient from '@/services/api/client/apiClient';
import { AxiosRequestConfig } from 'axios';

export class ZephyrAnnotationService extends GenericService<Annotation> {
  endpoint = 'v1/annotations';

  async update(
    id: string | number,
    changes: Partial<Annotation>,
    params: AxiosRequestConfig = {}
  ): Promise<Annotation> {
    return (await apiClient.put(`${this.endpoint}/${id}`, changes, params)).data;
  }
}
