import { NavigationGuardNext, RouteLocationNormalized, RouteRecord } from 'vue-router';
import * as Sentry from '@sentry/vue';
import tokenService from '@/services/token.service';
import { i18n } from '@/i18n/i18n';
import { useHistoryStore } from '@/stores/history.store';
import { useSessionStore } from '@/stores/session.store';
import { usePatientStore } from '@/stores/patient.store';
import { useUiStore } from '@/stores/ui.store';
import { useNotificationStore } from '@/stores/notification.store';
import { authStateService } from '@/services/auth';

export const beforeEach = async (
  routeTo: RouteLocationNormalized,
  routeFrom: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const historyStore = useHistoryStore();
  const sessionStore = useSessionStore();
  const patientStore = usePatientStore();
  const notificationStore = useNotificationStore();
  const uiStore = useUiStore();
  if (routeFrom.name !== routeTo.name && routeFrom.name) {
    historyStore.from = routeFrom.name;
  }
  historyStore.to = routeTo.name ?? 'patient-list';

  if (isChrome() || isFirefox()) {
    if (routeTo.meta.authRequired === false && routeTo.name !== 'login') {
      return next();
    }

    // All routes require authentication by default - check if authentication is not required
    const authRequired = !routeTo.matched.some((route: RouteRecord) => !route.meta.authRequired);

    // Get Authenticated status
    let authenticated = !!sessionStore.currentUser;

    // If we are not token authenticated, try session authentication
    if (!authenticated) {
      try {
        uiStore.loading = true;
        await sessionStore.fetchCurrentUserAndSetOrganisation(true);
        authenticated = true;
        authStateService.login();
        await sessionStore.fetchAdminCustomer();
        await patientStore.fetchExternalPatientReferenceTypes();
      } catch (e) {
        // Do nothing
      } finally {
        uiStore.loading = false;
      }
    }

    // Redirect to login if auth required and user not authenticated
    if (authRequired && !authenticated) {
      return next({ name: 'login' });
    }

    // user is authenticated
    if (authenticated) {
      // if no current User in store, we run fetchCurrentUserAndSetOrganisation;
      if (!sessionStore.currentUser) {
        try {
          uiStore.loading = true;
          await sessionStore.fetchCurrentUserAndSetOrganisation();
          await sessionStore.fetchAdminCustomer();
          await patientStore.fetchExternalPatientReferenceTypes();
        } catch (e) {
          tokenService.removeToken();
          return next({ name: 'login' });
        } finally {
          uiStore.loading = false;
        }
      }

      // setup sentry user context
      const currentUser = sessionStore.currentUser;
      if (currentUser) {
        Sentry.configureScope((scope) => {
          scope.setUser({
            id: currentUser.id,
            email: currentUser.email
          });
        });
      }
      if (!routeTo.meta?.isAdmin && routeTo.params.organisationId) {
        if (sessionStore.getOrganisation(routeTo.params.organisationId as string)) {
          sessionStore.setCurrentOrganisationId(routeTo.params.organisationId as string);
        } else {
          return next({ name: '401' });
        }
      } else if (routeTo.query?.customer) {
        sessionStore.setCurrentOrganisationIdForCustomer(routeTo.query?.customer as string);
      }

      const hasCurrentOrganisation =
        typeof localStorage.getItem('organisationId') !== 'undefined' &&
        localStorage.getItem('organisationId') !== null &&
        localStorage.getItem('organisationId') !== 'null';

      // 1. Check if user has agreed the current customer's terms.
      if (
        routeTo.name !== 'user-agreement' &&
        currentUser &&
        sessionStore.policiesToAgreeTo.length
      ) {
        return next({ name: 'user-agreement' });
      }

      // 2. User has agreed to terms
      if (!sessionStore.policiesToAgreeTo.length) {
        // 2.1 If user is not admin, they are forbidden from accessing an admin route
        if (routeTo.meta?.isAdmin && currentUser && !currentUser.is_admin) {
          notificationStore.snackbar = {
            label: i18n.global.t('platform.error.403'),
            autoDismiss: true,
            dismissAfter: 6000,
            color: 'danger',
            icon: 'warning'
          };

          return next({ path: routeFrom.path || '/' });
        }

        // 2.2 If user is admin, log in and redirect to Domain Admin
        if (routeTo.meta?.isAdmin && currentUser?.is_admin) {
          return next();
        }

        // 2.3 If a user has at least one organisation
        if (hasCurrentOrganisation) {
          const permissionList = sessionStore.permissions;

          // 2.3.1 If user has no permission to see Patient list but can see Participant list, redirect to Participant list
          if (
            routeTo.name === 'patient-list' &&
            !permissionList.includes('patient:read') &&
            permissionList.includes('anonymous-participant:read')
          ) {
            return next({ name: 'participant-list', params: { organisationId: routeTo.params?.organisationId } });
          }

          // 2.3.2 If user has no permission to see Participant list but can see Patient list, redirect to Patient list
          if (
            routeTo.name === 'participant-list' &&
            permissionList.includes('patient:read') &&
            !permissionList.includes('anonymous-participant:read')
          ) {
            return next({ name: 'patient-list', params: { organisationId: routeTo.params?.organisationId } });
          }

          // 2.3.3 If user has no permission to see Participant nor Patient list, redirect to Profile
          if (
            (routeTo.name === 'participant-list' && !permissionList.includes('anonymous-participant:read')) ||
            (routeTo.name === 'patient-list' && !permissionList.includes('patient:read'))
          ) {
            return next({ name: 'organisation-user-profile', params: { organisationId: routeTo.params?.organisationId } });
          }

          // 2.3.4 If user tries to access the organisations settings, redirect to the page they have access to
          const organisationSettingsPermissions = {
            'user:read': 'settings-users',
            'role:read': 'settings-roles',
            'device:read': 'settings-devices',
            'org-unit:read': 'settings-escalation-emails',
            'org-unit-preference:update': 'settings-preferences'
          };
          if (routeTo.name === 'organisation-settings') {
            const permission = Object.keys(organisationSettingsPermissions).find((routePermission) =>
              permissionList.includes(routePermission)
            );
            if (permission) {
              return next({
                name: organisationSettingsPermissions[permission],
                params: {
                  ...routeTo.params
                }
              });
            }
            return next({
              name: 'organisation-user-profile',
              params: {
                ...routeTo.params
              }
            });
          }

          // 2.3.4 Check permissions: if permission required and user does not have permission, redirect to 403.
          if (routeTo.meta?.permission && !permissionList.includes(routeTo.meta.permission as string)) {
            notificationStore.snackbar = {
              label: i18n.global.t('platform.error.403'),
              autoDismiss: true,
              dismissAfter: 6000,
              color: 'danger',
              icon: 'warning'
            };

            return next({ path: routeFrom.path || '/' });
          }

          // 2.3.5 If user is authenticated but route is a login, cancel route transition
          if (routeTo.meta?.loginPage) {
            if (currentUser?.is_admin) {
              return next({ name: 'domain-admin' });
            }
            if (permissionList.includes('anonymous-participant:read')) {
              return next({ name: 'participant-list', params: { organisationId: sessionStore.currentOrganisationId } });
            }
            return next({ name: 'patient-list', params: { organisationId: sessionStore.currentOrganisationId } });
          }
        }

        // 2.4 User is always allowed to access their profile and account settings
        if (routeTo.meta?.isUserSettings) {
          if (routeTo.path.startsWith('/account-settings')) {
            if (sessionStore.currentUser?.is_admin) {
              return next({ name: 'domain-admin-settings' });
            }
            if (sessionStore.currentUser?.organisations?.length) {
              return next({
                name: 'organisation-user-settings',
                params: {
                  organisationId: sessionStore.currentOrganisationId ?? sessionStore.currentUser?.organisations[0]?.id
                }
              });
            }
          }
          return next();
        }

        if (!hasCurrentOrganisation) {
          // 2.5 If user has no organisation and is an admin, go to domain admin
          if (currentUser?.is_admin) {
            return next({ name: 'domain-admin' });
          }

          // 2.6 If user has no organisation and is not an admin, go to settings
          return next({ name: 'settings' });
        }
      }
    }
  } else if (routeTo.name !== 'unsupported-browser') {
    return next({ name: 'unsupported-browser' });
  }

  return next();
};
declare const InstallTrigger: any;

const isFirefox = () => typeof InstallTrigger !== 'undefined';

const isChrome = () => {
  // @ts-ignore
  const isChromium = window.chrome;
  const winNav = window.navigator;
  const vendorName = winNav.vendor;
  // @ts-ignore
  const isOpera = typeof window.opr !== 'undefined';
  const isIEedge = winNav.userAgent.indexOf('Edge') > -1;
  const isIOSChrome = winNav.userAgent.match('CriOS');
  const isHeadlessChrome = winNav.userAgent.match('HeadlessChrome');

  return !!(
    isIOSChrome ||
    isHeadlessChrome ||
    (isChromium !== null && typeof isChromium !== 'undefined' && vendorName === 'Google Inc.' && !isOpera && !isIEedge)
  );
};
