import { GenericService } from './generic.service';
import apiClient from '@/services/api/client/apiClient';
import { ICodeValueEntity } from '@/models';
import { AxiosResponse } from 'axios';

export class CopdModifiedAnthonisenHealthRecordService extends GenericService<ICodeValueEntity[]> {
  constructor() {
    super('/health-record/ordinals/uhb-modified-anthonisen-criteria');
  }

  async getBreathlessness(): Promise<AxiosResponse<any[]>> {
    return await apiClient.get(`${this.endpoint}/breathlessness`);
  }

  async getSputumProd(): Promise<AxiosResponse<any[]>> {
    return await apiClient.get(`${this.endpoint}/sputum-production`);
  }

  async getSputumColor(): Promise<AxiosResponse<any[]>> {
    return await apiClient.get(`${this.endpoint}/sputum-colour`);
  }

  async getWheeze(): Promise<AxiosResponse<any[]>> {
    return await apiClient.get(`${this.endpoint}/wheeze`);
  }

  async getCough(): Promise<AxiosResponse<any[]>> {
    return await apiClient.get(`${this.endpoint}/cough`);
  }

  async getSoreThroat(): Promise<AxiosResponse<any[]>> {
    return await apiClient.get(`${this.endpoint}/sore-throat`);
  }

  async getRunnyNose(): Promise<AxiosResponse<any[]>> {
    return await apiClient.get(`${this.endpoint}/congested-runny-nose`);
  }
}
