import apiClient from '@/services/api/client/apiClient';
export class ZephyrService {
    endpoint;
    constructor(patientId) {
        this.endpoint = `v1/patients/${patientId}/studies`;
    }
    async getStudies(params = {}) {
        return (await apiClient.get(this.endpoint, params)).data;
    }
}
