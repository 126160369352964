import { i18n } from '@/i18n/i18n';
import {
  Encounter,
  EncounterTypeName,
  EndedReasonCode,
  EndPrescriptionValue,
  KUnit,
  Laterality
} from '@/custom/menicon/models';
import { REORDER_REASONS } from '@/custom/menicon/constants';

export const isSet = (value: any): boolean => {
  if (Array.isArray(value)) {
    return !!value.length;
  }

  if (typeof value === 'undefined' || value === null) {
    return false;
  }

  if (value === false) {
    return true;
  }

  if (value instanceof Date) {
    // invalid date won't pass
    return !isNaN(value.getTime());
  }

  if (typeof value === 'object') {
    return isSet(Object.values(value));
  }

  return !!String(value).trim().length;
};

export const isNumeric = (value: any): boolean => !value || /^[-]?\d*(\.\d+)?$/.test(value);

export const respectsStep =
  (step: number, min = 0) =>
    (value: any): boolean =>
      !isSet(value) || (+value - min) % step === 0;

export const isBetween = (min: number, max: number) => (value: any) =>
  !isSet(value) || (!/\s/.test(value) && +min <= +value && +max >= +value);

export const required = (value: any, message?: string): string | null =>
  isSet(value) ? null : message || (i18n.global.t('platform.error.required') as string);

export const numeric = (value: any, message?: string): string | null =>
  isNumeric(value) ? null : message || (i18n.global.t('platform.error.numeric') as string);

export const between =
  (min: number, max: number) =>
    (value: any, message?: string): string | null =>
      isBetween(min, max)(value) ? null : message || (i18n.global.t('platform.error.between', [min, max]) as string);

export const step =
  (step: number, min = 0) =>
    (value: any, message?: string): string | null =>
      respectsStep(step, min)(value) ? null : message || (i18n.global.t('platform.error.step', [step]) as string);

export const validate = (rules: { [key: string]: any }, value: { [key: string]: any }) =>
  Object.keys(rules).reduce((acc: any, key: any) => {
    const obj = rules[key];
    const nextValue: any = value?.[key];
    if (typeof obj === 'function') {
      if (!Array.isArray(acc)) {
        // eslint-disable-next-line no-param-reassign
        acc = [];
      }
      if (obj(value)) {
        acc.push(obj(value));
      }
    } else {
      acc[key] = validate(obj, nextValue);
    }
    return acc;
  }, {} as { [key: string]: any });

interface ErrorObject {
  [key: string]: ErrorObject | string[] | null;
}

type ValidationRules = Record<string, (value: any, message?: string) => string | null>;

type EncounterSectionRules = {
  [key in keyof Encounter]?: ValidationRules;
};

export interface EncounterRules {
    vaRefraction?: EncounterSectionRules;
    slitLamp?: EncounterSectionRules;
    preFittingSlitLamp?: EncounterSectionRules;
    reasonForReorder?: EncounterSectionRules;
    patientScans?: EncounterSectionRules;
    measurements?: EncounterSectionRules;
    differenceMaps?: EncounterSectionRules;
    distanceVa?: EncounterSectionRules;
    vaAndLensAssessment?: EncounterSectionRules;
}

export const countErrors = (errors: ErrorObject): number =>
  errors ? Object.keys(errors).reduce((acc: number, key) => {
    const value = errors?.[key];
    if (Array.isArray(value)) {
      return acc + (value.length ? 1 : 0);
    } else if (!value) {
      return acc;
    }
    return acc + countErrors(value);
  }, 0) : 0;

export const getEncounterValidationRules =
    (encounter: Encounter, journeyLaterality: Laterality | null = null): EncounterRules => {
      const rightEyeRequired = journeyLaterality
        ? journeyLaterality !== Laterality.left
        : encounter.laterality !== Laterality.left;
      const leftEyeRequired = journeyLaterality
        ? journeyLaterality !== Laterality.right
        : encounter.laterality !== Laterality.right;

      const flatKMin = encounter.k_unit === KUnit.DIOPTRES ? 33.91 : 5;
      const flatKMax = encounter.k_unit === KUnit.DIOPTRES ? 67.5 : 9.95;
      const hvidMin = 10.5;
      const hvidMax = 13.0;
      const vaRefractionDefaultRules = {
        r_sphere: {
          ...(rightEyeRequired ? { required } : {}),
          numeric,
          between: between(-20, 20),
          step: step(0.25)
        },
        r_cylinder: {
          numeric,
          between: between(-10, 0),
          step: step(0.25)
        },
        r_axis: {
          ...(!encounter?.r_cylinder?.length || +encounter.r_cylinder === 0 || !rightEyeRequired ? {} : { required }),
          numeric,
          between: between(0, 180)
        },
        l_sphere: {
          ...(leftEyeRequired ? { required } : {}),
          numeric,
          between: between(-20, 20),
          step: step(0.25)
        },
        l_cylinder: {
          numeric,
          between: between(-10, 0),
          step: step(0.25)
        },
        l_axis: {
          ...(!encounter?.l_cylinder?.length || +encounter.l_cylinder === 0 || !leftEyeRequired ? {} : { required }),
          numeric,
          between: between(0, 180)
        },
        r_axial_length: {
          numeric,
          between: between(10, 40)
        },
        l_axial_length: {
          numeric,
          between: between(10, 40)
        },
        r_addition: {
          numeric,
          between: between(0, 5)
        },
        l_addition: {
          numeric,
          between: between(0, 5)
        }
      };
      const distanceVaDefaultRules = {
        r_distance_visual_acuity: rightEyeRequired ? { required } : {},
        l_distance_visual_acuity: leftEyeRequired ? { required } : {},
        combined_distance_visual_acuity: rightEyeRequired && leftEyeRequired ? { required } : {},
        is_va_satisfactory: {
          required
        }
      };
      const vaAndLensAssessmentRules = {
        r_distance_visual_acuity: { required },
        l_distance_visual_acuity: { required },
        combined_distance_visual_acuity: { required },
        l_is_va_satisfactory: { required },
        r_is_va_satisfactory: { required }
      };
      const vaRequiredRules = {
        r_visual_acuity: rightEyeRequired ? { required } : {},
        l_visual_acuity: leftEyeRequired ? { required } : {},
        combined_visual_acuity: rightEyeRequired && leftEyeRequired ? { required } : {}
      };
      const patientScansDefaultRules = {
        r_mls_id: rightEyeRequired ? { required } : {},
        r_hvid: {
          ...(rightEyeRequired && (encounter.type === EncounterTypeName.INITIAL_MEASUREMENT ||
        encounter.type === EncounterTypeName.QUICK_INITIAL_MEASUREMENT) ? { required } : {}
          ),
          between: between(hvidMin, hvidMax)
        },
        l_mls_id: leftEyeRequired ? { required } : {},
        l_hvid: {
          ...(leftEyeRequired && (encounter.type === EncounterTypeName.INITIAL_MEASUREMENT ||
        encounter.type === EncounterTypeName.QUICK_INITIAL_MEASUREMENT) ? { required } : {}
          ),
          between: between(hvidMin, hvidMax)
        }
      };
      const differenceMapsDefaultRules = {
        ...(rightEyeRequired
          ? {
            r_is_expected_treatment_zone: {
              required
            },
            r_is_expected_centration: {
              required
            },
            ...(encounter.r_is_expected_treatment_zone === false && encounter.r_is_expected_centration === true
              ? {
                r_pattern: {
                  required
                }
              }
              : {})
          }
          : {}),
        ...(leftEyeRequired
          ? {
            l_is_expected_treatment_zone: {
              required
            },
            l_is_expected_centration: {
              required
            },
            ...(encounter.l_is_expected_treatment_zone === false && encounter.l_is_expected_centration === true
              ? {
                l_pattern: {
                  required
                }
              }
              : {})
          }
          : {})
      };
      const measurementRules = {
        r_flat_k: {
          ...(rightEyeRequired ? { required } : {}),
          numeric,
          between: between(flatKMin, flatKMax)
        },
        l_flat_k: {
          ...(leftEyeRequired ? { required } : {}),
          numeric,
          between: between(flatKMin, flatKMax)
        },
        r_steep_k: {
          ...(rightEyeRequired ? { required } : {}),
          numeric,
          between:
        encounter.k_unit === KUnit.DIOPTRES
          ? between(+(encounter.r_flat_k || flatKMin), flatKMax)
          : between(flatKMin, +(encounter.r_flat_k || flatKMax))
        },
        l_steep_k: {
          ...(leftEyeRequired ? { required } : {}),
          numeric,
          between:
        encounter.k_unit === KUnit.DIOPTRES
          ? between(+(encounter.l_flat_k || flatKMin), flatKMax)
          : between(flatKMin, +(encounter.l_flat_k || flatKMax))
        },
        r_flat_k_axis: {
          ...(rightEyeRequired ? { required } : {}),
          numeric,
          between: between(0, 180)
        },
        l_flat_k_axis: {
          ...(leftEyeRequired ? { required } : {}),
          numeric,
          between: between(0, 180)
        },
        r_hvid: {
          ...(rightEyeRequired ? { required } : {}),
          numeric,
          between: between(hvidMin, hvidMax)
        },
        l_hvid: {
          ...(leftEyeRequired ? { required } : {}),
          numeric,
          between: between(hvidMin, hvidMax)
        }
      };

      const slitLampDefaultRules = {
        slit_lamp_conditions: {
          ...(encounter?.slit_lamp_conditions && Object.keys(encounter.slit_lamp_conditions).length ?
            Object.keys(encounter.slit_lamp_conditions).reduce((acc, condition) => ({
              ...acc,
              [condition]: {
                r_grading: {
                  ...(rightEyeRequired ? { required } : {})
                },
                l_grading: {
                  ...(leftEyeRequired ? { required } : {})
                }
              }
            }), {}) : encounter?.is_slit_lamp_condition_none ? {} : {
              required
            })
        }
      };

      switch (encounter.type) {
      case EncounterTypeName.BLOOM_NIGHT_COLLECTION:
        return {
          vaRefraction: {
            ...vaRefractionDefaultRules,
            ...vaRequiredRules
          },
          slitLamp: slitLampDefaultRules,
          preFittingSlitLamp: {
            pre_fitting_slit_lamp_conditions: {
              ...(encounter?.pre_fitting_slit_lamp_conditions &&
            Object.keys(encounter.pre_fitting_slit_lamp_conditions).length ?
                Object.keys(encounter.pre_fitting_slit_lamp_conditions).reduce((acc, condition) => ({
                  ...acc,
                  [condition]: {
                    r_grading: {
                      ...(rightEyeRequired ? { required } : {})
                    },
                    l_grading: {
                      ...(leftEyeRequired ? { required } : {})
                    }
                  }
                }), {}) : encounter?.pre_fitting_is_slit_lamp_condition_none ? {} : {
                  required
                })
            }
          }
        };
      case EncounterTypeName.LENS_REORDER:
        return {
          reasonForReorder: {
            reorder_reasons: {
              required
            },
            reorder_other_details: {
              ...(encounter.reorder_reasons?.includes(REORDER_REASONS.OTHER_REASON) ? { required } : {})
            }
          }
        };
      case EncounterTypeName.BLOOM_NIGHT_3_OR_9_MONTHS_ASSESSMENT:
        return {
          vaRefraction: {
            ...vaRefractionDefaultRules,
            ...vaRequiredRules
          },
          slitLamp: slitLampDefaultRules
        };
      case EncounterTypeName.BLOOM_NIGHT_1_NIGHT_FOLLOW_UP:
      case EncounterTypeName.BLOOM_NIGHT_1_WEEK_FOLLOW_UP:
        return {
          vaRefraction: {
            ...vaRefractionDefaultRules,
            ...vaRequiredRules,
            combined_visual_acuity: {}
          },
          patientScans: patientScansDefaultRules,
          slitLamp: slitLampDefaultRules
        };
      case EncounterTypeName.INITIAL_MEASUREMENT:
        return {
          vaRefraction: {
            ...vaRefractionDefaultRules,
            is_cyclopegic_refraction: {
              required
            }
          },
          measurements: measurementRules,
          patientScans: patientScansDefaultRules,
          slitLamp: slitLampDefaultRules
        };
      case EncounterTypeName.QUICK_INITIAL_MEASUREMENT:
        return {
          vaRefraction: vaRefractionDefaultRules,
          measurements: measurementRules,
          patientScans: patientScansDefaultRules
        };
      case EncounterTypeName.BLOOM_NIGHT_3_WEEK_LENS_EFFICACY_ASSESSMENT:
        return {
          vaRefraction: {
            ...vaRefractionDefaultRules,
            ...vaRequiredRules
          },
          patientScans: patientScansDefaultRules,
          differenceMaps: differenceMapsDefaultRules,
          slitLamp: slitLampDefaultRules
        };
      case EncounterTypeName.QUICK_DIFFERENCE_MAP_ASSESSMENT:
        return {
          patientScans: patientScansDefaultRules,
          differenceMaps: differenceMapsDefaultRules
        };
      case EncounterTypeName.BLOOM_NIGHT_6_OR_12_MONTHS_ASSESSMENT:
        return {
          vaRefraction: {
            ...vaRefractionDefaultRules,
            ...vaRequiredRules,
            is_cyclopegic_refraction: {
              required
            }
          },
          patientScans: patientScansDefaultRules,
          differenceMaps: differenceMapsDefaultRules,
          slitLamp: slitLampDefaultRules
        };
      case EncounterTypeName.BLOOM_DAY_1_WEEK_LENS_EFFICACY_ASSESSMENT:
      case EncounterTypeName.BLOOM_DAY_LENS_COLLECTION:
        return {
          distanceVa: distanceVaDefaultRules,
          slitLamp: slitLampDefaultRules
        };
      case EncounterTypeName.QUICK_LENS_EFFICACY_ASSESSMENT:
        return {
          distanceVa: {
            is_va_satisfactory: {
              required
            }
          }
        };
      case EncounterTypeName.BLOOM_DAY_3_OR_9_MONTHS_HEALTH_ASSESSMENT:
        return {
          distanceVa: distanceVaDefaultRules,
          slitLamp: slitLampDefaultRules
        };
      case EncounterTypeName.BLOOM_DAY_6_OR_12_MONTHS_PROGRESS_ASSESSMENT:
        return {
          vaAndLensAssessment: vaAndLensAssessmentRules,
          vaRefraction: {
            ...vaRefractionDefaultRules,
            ...vaRequiredRules
          },
          slitLamp: slitLampDefaultRules
        };
      default:
        return {};
      }
    };

export const getEndPrescriptionValidationErrors = (
  endPrescriptionValue: EndPrescriptionValue
): { [key: string]: string[] } => {
  const rules = {
    reasons: { required },
    ...(endPrescriptionValue.reasons.includes(EndedReasonCode.END_PROGRAM_OTHER_REASON)
      ? {
        details: {
          required
        }
      }
      : {})
  };
  return validate(rules, endPrescriptionValue);
};
