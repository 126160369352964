import apiClient from '@/services/api/client/apiClient';
import { AxiosRequestConfig } from 'axios';

export class ZephyrImageService {
  endpoint = 'v1/zephyr-images';

  /*
  Window origin is required because cornerstone's ImageLoader logic
  is generic and dependent on the protocol that is defined in the URL provided.
  For a detailed explanation see: https://github.com/cornerstonejs/cornerstone/wiki/ImageIds
   */
  getEndpointUrl() {
    return `${window.location.origin}${apiClient.defaults.baseURL}/${this.endpoint}`;
  }

  async getImage(imageId: string, config: AxiosRequestConfig = {}): Promise<string> {
    return await apiClient.get(`${this.endpoint}/${imageId}`, config);
  }
}
