import { DateTimeFormats as IntlDateTimeFormats } from '@intlify/core-base';

export const datetimeFormats: IntlDateTimeFormats = {
  'en-GB': {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    },
    shortMonth: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    },
    dateTime: {
      hour: '2-digit',
      minute: '2-digit',
      day: 'numeric',
      month: 'numeric',
      year: 'numeric'
    }
  },
  'ja-JP': {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    },
    shortMonth: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }
  },
  'de-DE': {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    },
    shortMonth: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }
  },
  'fr-FR': {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    },
    shortMonth: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }
  },
  'es-ES': {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    },
    shortMonth: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }
  },
  'en-US': {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    },
    shortMonth: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }
  }
};
