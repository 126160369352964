import { i18n } from '@/i18n/i18n';
import { ITranslatedOption } from '@/lib';
import { UhbModifiedAnthonisenCriteriaObservation } from '@/models';

export const changeHomeMonitoringPlanReasons: Array<ITranslatedOption> = [
  {
    label: i18n.global.t('custom.uhb.copd.change-plan-reason.set-next-home-visit-or-telephone-consult'),
    value: 'setNextHomeVisitOrTelephoneConsult'
  },
  {
    label: i18n.global.t('custom.uhb.copd.change-plan-reason.general-clinical-improvement'),
    value: 'generalClinicalImprovement'
  },
  {
    label: i18n.global.t('custom.uhb.copd.change-plan-reason.not-improving-as-expected'),
    value: 'notImprovingAsExpected'
  },
  {
    label: i18n.global.t('custom.uhb.copd.change-plan-reason.generalised-clinical-deterioration'),
    value: 'generalisedClinicalDeterioration'
  },
  {
    label: i18n.global.t('custom.uhb.copd.change-plan-reason.unexpected-result-in-recording'),
    value: 'unexpectedResultInRecording'
  },
  {
    label: i18n.global.t('custom.uhb.copd.change-plan-reason.patient-has-increased-their-o2'),
    value: 'patientHasIncreasedTheirO2'
  },
  {
    label: i18n.global.t('custom.uhb.copd.change-plan-reason.patient-requires-further-training'),
    value: 'patientRequiresFurtherTraining'
  },
  {
    label: i18n.global.t('custom.uhb.copd.change-plan-reason.change-in-medication'),
    value: 'changeInMedication'
  },
  {
    label: i18n.global.t('custom.uhb.copd.change-plan-reason.equipment-fault'),
    value: 'equipmentFault'
  },
  {
    label: i18n.global.t('custom.uhb.copd.change-plan-reason.reason-not-listed'),
    value: 'reasonNotListed'
  }
];

export const dischargeReasons: Array<ITranslatedOption> = [
  {
    label: i18n.global.t('custom.uhb.copd.discharge-reason.patient-not-discharged-from-hospital'),
    value: 'patientNotDischargedFromHospital'
  },
  {
    label: i18n.global.t('custom.uhb.copd.discharge-reason.patient-failed-technical-training'),
    value: 'patientFailedTechnicalTraining'
  },
  {
    label: i18n.global.t('custom.uhb.copd.discharge-reason.patient-improved-and-discharged'),
    value: 'patientImprovedAndDischarged'
  },
  {
    label: i18n.global.t('custom.uhb.copd.discharge-reason.readmitted-for-copd'),
    value: 'readmittedForCopd'
  },
  {
    label: i18n.global.t('custom.uhb.copd.discharge-reason.admitted-to-hospital-for-non-copd-issue'),
    value: 'admittedToHospitalForNonCopdIssue'
  },
  {
    label: i18n.global.t('custom.uhb.copd.discharge-reason.patient-deceased'),
    value: 'patientDeceased'
  },
  {
    label: i18n.global.t('custom.uhb.copd.discharge-reason.patient-non-compliant-with-virtual-entries'),
    value: 'patientNonCompliantWithVirtualEntries'
  },
  {
    label: i18n.global.t('custom.uhb.copd.discharge-reason.patient-not-happy-doing-virtual-entries'),
    value: 'patientNotHappyDoingVirtualEntries'
  },
  {
    label: i18n.global.t('custom.uhb.copd.discharge-reason.patient-having-technical-difficulty'),
    value: 'patientHavingTechnicalDifficulty'
  },
  {
    label: i18n.global.t('custom.uhb.copd.discharge-reason.reason-not-listed'),
    value: 'reasonNotListed'
  },
  {
    label: i18n.global.t('custom.uhb.copd.discharge-reason.patient-merged'),
    value: 'patientMerged',
    readonly: true
  },
  {
    label: i18n.global.t('custom.uhb.copd.discharge-reason.clinician-error'),
    value: 'clinicianError'
  }
];

export const dischargeReasonsCopdAa: Array<ITranslatedOption> = [
  {
    label: i18n.global.t('custom.uhb.copd.discharge-reason.patient-failed-technical-training'),
    value: 'patientFailedTechnicalTraining'
  },
  {
    label: i18n.global.t('custom.uhb.copd.discharge-reason.patient-improved-and-discharged'),
    value: 'patientImprovedAndDischarged'
  },
  {
    label: i18n.global.t('custom.uhb.copd.discharge-reason.admitted-for-copd'),
    value: 'admittedForCopd'
  },
  {
    label: i18n.global.t('custom.uhb.copd.discharge-reason.admitted-to-hospital-for-non-copd-issue'),
    value: 'admittedToHospitalForNonCopdIssue'
  },
  {
    label: i18n.global.t('custom.uhb.copd.discharge-reason.patient-deceased'),
    value: 'patientDeceased'
  },
  {
    label: i18n.global.t('custom.uhb.copd.discharge-reason.patient-non-compliant-with-virtual-entries'),
    value: 'patientNonCompliantWithVirtualEntries'
  },
  {
    label: i18n.global.t('custom.uhb.copd.discharge-reason.patient-not-happy-doing-virtual-entries'),
    value: 'patientNotHappyDoingVirtualEntries'
  },
  {
    label: i18n.global.t('custom.uhb.copd.discharge-reason.patient-having-technical-difficulty'),
    value: 'patientHavingTechnicalDifficulty'
  },
  {
    label: i18n.global.t('custom.uhb.copd.discharge-reason.reason-not-listed'),
    value: 'reasonNotListed'
  },
  {
    label: i18n.global.t('custom.uhb.copd.discharge-reason.clinician-error'),
    value: 'clinicianError'
  }
];

export const selectableDischargeReasons = (): Array<ITranslatedOption> =>
  dischargeReasons.filter((option) => !option.readonly);

export const consultTypes: Array<ITranslatedOption> = [
  {
    label: i18n.global.t('custom.uhb.copd.consult-options.home-visit'),
    value: 'homeVisit'
  },
  {
    label: i18n.global.t('custom.uhb.copd.consult-options.telephone-consult'),
    value: 'telephoneConsult'
  }
];

export const reviewOutcomes: Array<ITranslatedOption> = [
  {
    label: i18n.global.t('custom.uhb.copd.outcome-options.change-current-plan'),
    value: 'change'
  },
  {
    label: i18n.global.t('custom.uhb.copd.outcome-options.continue-current-plan'),
    value: 'continue'
  },
  {
    label: i18n.global.t('custom.uhb.copd.outcome-options.discharge-patient'),
    value: 'discharge'
  }
];

export const measurementReadingsList = (measurementCriteria: UhbModifiedAnthonisenCriteriaObservation): Array<any> => [
  {
    label: i18n.global.t('custom.uhb.copd.breathlessness'),
    value: measurementCriteria.breathlessness.symbol.label,
    alert: measurementCriteria.breathlessness.symbol.code === 'at0005' // worse
  },
  {
    label: i18n.global.t('custom.uhb.copd.amount-of-sputum'),
    value: measurementCriteria.sputum_production.symbol.label,
    alert: false
  },
  {
    label: i18n.global.t('custom.uhb.copd.color-of-sputum'),
    value: measurementCriteria.sputum_colour.symbol.label,
    alert: false
  },
  {
    label: i18n.global.t('custom.uhb.copd.wheeze'),
    value: measurementCriteria.wheeze.symbol.label,
    alert: false
  },
  {
    label: i18n.global.t('custom.uhb.copd.cough'),
    value: measurementCriteria.cough.symbol.label,
    alert: false
  },
  {
    label: i18n.global.t('custom.uhb.copd.sore-throat'),
    value: measurementCriteria.sore_throat.symbol.label,
    alert: false
  },
  {
    label: i18n.global.t('custom.uhb.copd.runny-congested-nose'),
    value: measurementCriteria.congested_runny_nose.symbol.label,
    alert: false
  }
];
