import TokenService from '@/services/token.service';
import * as Sentry from '@sentry/vue';
import router from '@/router';
import { useSessionStore } from '@/stores/session.store';
import { FilterStorageService, IdlesService } from '@/services';
import authStateService from './state.service';
import { HomeMonitoringPathways } from '@/lib/components/Programs/IbdProgram/constants';

const logoutService = async (revokeToken = true) => {
  const sessionStore = useSessionStore();
  try {
    if (revokeToken) {
      await sessionStore.revokeUserToken({
        token: sessionStore.refreshToken
      });
    }
  } catch (e) {
    console.warn('Unable to revoke user token');
  } finally {
    IdlesService.removeAll();
    TokenService.removeToken();
    FilterStorageService.remove([
      HomeMonitoringPathways.CopdEarlySupportedDischarge,
      HomeMonitoringPathways.CopdAdmissionAvoidance
    ]);
    sessionStore.setCurrentUser(null);
    authStateService.logout();
    Sentry.configureScope((scope) => scope.setUser({}));
    if (router.currentRoute.value.name !== 'login') {
      await router.push({ name: 'login' });
    }
  }
};

export default logoutService;
