import { i18n } from '@/i18n/i18n';
import { IOption } from '@/lib';
const { t } = i18n.global;

export const YES_NO_RADIO: IOption[] = [
  {
    value: 'Yes',
    label: t('platform.common.yes')
  },
  {
    value: 'No',
    label: t('platform.common.no')
  }
];

// TODO - this will be comming from BE endpoint (For IBD Setup form)
export const PREFERED_METHOD_FOR_TEST: IOption[] = [
  {
    value: 'Home testing kit',
    label: 'Home testing kit'
  },
  {
    value: 'Laboratory test',
    label: 'Laboratory test'
  }
];

export const CONSULT_TYPE: IOption[] = [
  {
    value: 'Telephone call',
    label: t('custom.uhb.review.consult-type.telephone-call')
  },
  {
    value: 'Video call',
    label: t('custom.uhb.review.consult-type.video-call')
  },
  {
    value: 'Face to face',
    label: t('custom.uhb.review.consult-type.face-to-face')
  },
  {
    value: 'Remote review telephone (flare clinic)',
    label: t('custom.uhb.review.consult-type.remote-review-telephone')
  }
];

export const CLINICIAN_TYPE: IOption[] = [
  {
    value: 'Consultant',
    label: t('custom.uhb.review.clinician-type.consultant')
  },
  {
    value: 'Clinical nurse specialist',
    label: t('custom.uhb.review.clinician-type.clinical-nurse-specialist')
  }
];
