/**
 * Convert an object with keys in snake_case or kebab-case to camelCase.
 * @param obj Object with keys in snake_case or kebab-case.
 */
export const useToCamelCase = (obj) => {
    const newObj = {};
    Object.keys(obj).forEach((key) => {
        const newKey = key.replace(/([-_][a-z])/gi, ($1) => $1.toUpperCase().replace('-', '').replace('_', ''));
        newObj[newKey] = obj[key];
    });
    return newObj;
};
export const useToKebabCase = (str) => str.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();
