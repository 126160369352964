import { JourneyTypeName, Laterality } from '@/custom/menicon/models';
import { between, required, step, validate } from '@/custom/menicon/helpers/validators.helper';
import { DifferenceMapsAssessment } from '@/custom/menicon/constants';
export const lensParamsToLensFitting = (lensParams) => lensParams.reduce((r, k) => {
    r[k.name] = k.value;
    return r;
}, {});
export const lensFittingtoLensParams = (lensFitting, materialName) => Object.keys(lensFitting).reduce((acc, name) => {
    let options = [];
    if (name === 'TrialLens') {
        options = [
            {
                label: 'Yes',
                value: 1,
                id: 0
            },
            {
                label: 'No',
                value: 0,
                id: 1
            }
        ];
    }
    if (name === 'RegressionCompensation') {
        options = [
            {
                label: 'Low',
                value: -0.5,
                id: 0
            },
            {
                label: 'Medium',
                value: -1,
                id: 1
            },
            {
                label: 'High',
                value: -1.5,
                id: 2
            }
        ];
    }
    if (name === 'Material') {
        options = [
            {
                label: materialName,
                value: lensFitting[name],
                id: 'true'
            }
        ];
    }
    const value = {
        name,
        value: lensFitting[name],
        step: null,
        min: null,
        max: null,
        readOnly: true,
        precision: null,
        visibility: 'Visible',
        options
    };
    return [...acc, value];
}, []);
export const getDifferenceMapsAssessment = (treatmentZoneAsExpected, centrationAsExpected, pattern) => {
    if (centrationAsExpected === false) {
        return DifferenceMapsAssessment.SMILEY_FACE;
    }
    if (treatmentZoneAsExpected === false && centrationAsExpected === true && pattern === 'b') {
        return DifferenceMapsAssessment.CENTRAL_ISLAND;
    }
    return DifferenceMapsAssessment.BULLS_EYE;
};
export const removeEyePrefix = (data) => Object.keys(data).reduce((acc, key) => ({
    ...acc,
    [key.substr(2)]: data[key]
}), {});
export const addEyePrefix = (data, laterality) => {
    const prefix = laterality === Laterality.right ? 'r_' : laterality === Laterality.left ? 'l_' : 'combined_';
    return Object.keys(data).reduce((acc, key) => ({
        ...acc,
        [`${prefix}${key}`]: data[key]
    }), {});
};
// sort grade
export const lensScores = ['Best', 'Good', 'Poor', 'NotUsable'];
export const sortLenses = (a, b) => {
    const aScore = lensScores.findIndex((score) => score === a.score);
    const bScore = lensScores.findIndex((score) => score === b.score);
    if (aScore === bScore) {
        if (a.familyName === JourneyTypeName.MENICON_BLOOM_DAY) {
            return 1;
        }
        if (b.familyName === JourneyTypeName.MENICON_BLOOM_DAY) {
            return -1;
        }
        return 0;
    }
    return aScore - bScore;
};
export const getBestLens = (sortedList) => {
    const rightList = sortedList.right.filter((lens) => !lens.disabled);
    const leftList = sortedList.left.filter((lens) => !lens.disabled);
    const bestRight = rightList[0] || null;
    const bestLeft = leftList[0] || null;
    if (bestLeft && bestRight) {
        const rightScore = lensScores.findIndex((score) => score === bestRight.score);
        const leftScore = lensScores.findIndex((score) => score === bestLeft.score);
        return rightScore < leftScore
            ? {
                id: bestRight.value,
                laterality: Laterality.right
            }
            : {
                id: bestLeft.value,
                laterality: Laterality.left
            };
    }
    return bestRight
        ? {
            id: bestRight.value,
            laterality: Laterality.right
        }
        : {
            id: bestLeft?.value || '',
            laterality: Laterality.left
        };
};
export const validateLensParameter = (parameter, value) => validate({
    [parameter.name]: {
        required,
        ...(parameter.max ? { between: between(+(parameter.min || 0), +parameter.max) } : {}),
        ...(parameter.step ? { step: step(parameter.step) } : {})
    }
}, {
    [parameter.name]: value
});
