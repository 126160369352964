import apiClient from '@/services/api/client/apiClient';
export class GenericService {
    endpoint;
    constructor(endpoint) {
        this.endpoint = endpoint;
    }
    async index(params = {}) {
        return await apiClient.get(this.endpoint, params);
    }
    async fetch(id, params = {}) {
        return (await apiClient.get(`${this.endpoint}/${id}`, params)).data;
    }
    async create(model, params = {}) {
        return await apiClient.post(this.endpoint, model, params);
    }
    async update(id, changes, params = {}) {
        return (await apiClient.patch(`${this.endpoint}/${id}`, changes, params)).data;
    }
    async delete(id, params = {}) {
        return (await apiClient.delete(`${this.endpoint}/${id}`, params)).data;
    }
}
