import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const directive = {
  mounted: (el, binding) => {
    if (binding.value) {
      disableBodyScroll(el);
    }
  },
  updated(el, binding) {
    if (binding.value) {
      disableBodyScroll(el);
    } else {
      enableBodyScroll(el);
    }
  },
  unmounted: (el) => {
    enableBodyScroll(el);
  }
};

export default directive;
