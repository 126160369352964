export const useIncrementDate = (increment, unitOfTime, initialDate = new Date()) => {
    let newDate;
    switch (unitOfTime) {
        case 'days':
            newDate = addDays(increment, initialDate);
            break;
        case 'weeks':
            newDate = addWeeks(increment, initialDate);
            break;
        case 'months':
            newDate = addMonths(increment, initialDate);
            break;
    }
    return newDate;
};
const addDays = (days, initialDate = new Date()) => {
    const date = new Date(initialDate);
    date.setDate(date.getDate() + +days);
    return date;
};
const addWeeks = (weeks, initialDate = new Date()) => {
    const date = new Date(initialDate);
    date.setDate(date.getDate() + +weeks * 7);
    return date;
};
const addMonths = (months, initialDate = new Date()) => {
    const date = new Date(initialDate);
    date.setMonth(date.getMonth() + +months);
    return date;
};
