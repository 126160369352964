import { AxiosRequestConfig } from 'axios';
import apiClient from '@/services/api/client/apiClient';
import { TechnicalIssue } from '@/models';

export class TechnicalIssueService {
  endpoint = 'v1/technical-issues';

  async index(params: AxiosRequestConfig = {}): Promise<Array<TechnicalIssue>> {
    return (await apiClient.get(this.endpoint, params)).data;
  }
}
