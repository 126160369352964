/*
 * This service allows the management
 * of authenticated state across tabs.
 */
const authStateService = {
  isLoggedIn() {
    return localStorage.getItem('logged_in') === 'true';
  },
  login() {
    localStorage.setItem('logged_in', 'true');
  },
  logout() {
    localStorage.removeItem('logged_in');
  }
};

export default authStateService;
