import { defineStore } from 'pinia';
import { RouteRecordName } from 'vue-router';

export type RootState = {
    from: string | RouteRecordName;
    to: string | RouteRecordName;
};

export const useHistoryStore = defineStore('history', {
  state: () => ({
    from: 'patient-list',
    to: 'patient-list'
  } as RootState)
});
