interface Idle {
  id: string;
  active: boolean;
}

export class IdlesService {
  static getIdles(): Array<Idle> {
    const idles = localStorage.getItem('idles');
    if (idles && idles.length) {
      return JSON.parse(idles);
    }
    return [];
  }

  static hasIdle(id: string | null) {
    const idles = IdlesService.getIdles();
    return Boolean(idles.find((i) => i.id === id));
  }

  static addIdle(id: string) {
    const idles = IdlesService.getIdles();
    const item: Idle = { id, active: true };
    localStorage.setItem('idles', JSON.stringify([...idles, item]));
  }

  static removeIdle(id: string) {
    const idles = IdlesService.getIdles();
    const newIdles = idles.filter((i: Idle) => i.id !== id);
    localStorage.setItem('idles', JSON.stringify(newIdles));
  }

  static setActive(id: string, active: boolean) {
    const idles = IdlesService.getIdles();
    const index = idles.findIndex((i: Idle) => i.id === id);
    if (index >= 0) {
      idles.splice(index, 1, {
        id,
        active
      });
      localStorage.setItem('idles', JSON.stringify(idles));
    }
  }

  static getActives() {
    const idles = IdlesService.getIdles();
    return idles.filter((i) => i.active);
  }

  static removeAll() {
    localStorage.setItem('idles', JSON.stringify([]));
  }
}
