import { ExternalPatientReferenceType } from '@/models';
import { defineStore } from 'pinia';
import { ExternalPatientReferenceTypeService } from '@/services/api';
import { UHB_NHS_NUMBER_KEY } from '@/constants';

const externalPatientReferenceTypeService = new ExternalPatientReferenceTypeService();

interface State {
  externalPatientReferenceTypes: ExternalPatientReferenceType[];
}

export const usePatientStore = defineStore('patient', {
  state: (): State => ({
    externalPatientReferenceTypes: []
  }),
  actions: {
    async fetchExternalPatientReferenceTypes() {
      this.externalPatientReferenceTypes = (await externalPatientReferenceTypeService.index()).data;
    }
  },
  getters: {
    showNhsNumber(): boolean {
      return !!this.externalPatientReferenceTypes.find((type) => type.key === UHB_NHS_NUMBER_KEY);
    }
  }
});
