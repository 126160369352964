import { createPopper } from '@popperjs/core/lib/popper-lite';
import { Instance } from '@popperjs/core/lib/types';
import flip from '@popperjs/core/lib/modifiers/flip';
import offset from '@popperjs/core/lib/modifiers/offset';
import { Placement } from '@popperjs/core/lib/enums';

const popperPlacement = { right: 'bottom-start', left: 'bottom-end' };

export const initPopper = (targetEl: HTMLElement, menuEl: HTMLElement, position: 'right' | 'left'): Instance => {
  Object.defineProperty(offset, 'options', {
    value: { offset: [0, 8] },
    writable: true
  });

  return createPopper(targetEl, menuEl, {
    placement: popperPlacement[position] as Placement,
    modifiers: [flip, offset]
  });
};

export const updatePopper = async (popperInstance: Instance, position?: 'right' | 'left') => {
  if (position) {
    await popperInstance.setOptions({
      placement: popperPlacement[position] as Placement
    });
  } else {
    await popperInstance.update();
  }
};

export { Instance };
